import { Button } from "primereact/button";
import React, { FC } from 'react';
import { tawreedTranslationService } from "../../../../common/translations";
import { GlobalContext } from "../../../../context";
import { AuthUtils } from "../../../auth/domain";
import { TawreedTable, TawreedTableProps } from "../../../../common";
import { Dialog } from "primereact/dialog";
import { CssUtils } from "../../../../helpers/css";
import { TawreedTableFormatters } from "../../../../common/components/table/formatters";
import { DashboardService } from "../../domain";
import { TawreedTableFilters } from "../../../../common/components/table/filters";
import { PartnerType } from "../../../partners/data";

export interface FinanceTransactionDialogProps {
    hide: () => void;
    xlsxReportURI?: string;
    // pdfReportURI: string;
    visible: boolean;
    balanceId?: number;
    startDate?: Date,
    endDate?: Date,
    name?: string,
}

export const FinanceTransactionDialog: FC<FinanceTransactionDialogProps> = ({ visible, hide, xlsxReportURI, balanceId,
     startDate, endDate, name}) => {

    const service = React.useMemo(() => new DashboardService(), []);
    const print = (report: string) => {
        return service.print(report)
            .then(() => {
            })
            .catch(err => console.error(err));
    };

    const FinanceTransactionDialogFooter = () => {
        return <div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={false} />

        </div>;
    }
    const FinanceTransactionDialogHeader = () => {
        return <div>
            <p>{tawreedTranslationService.translate(`lbl_finance_dashboard_${name}_chart_title`)}</p>
        </div>
    }
    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);

    const lang = AuthUtils.current().language;

    const tawreed: TawreedTableProps = {
        header: true,
        disableGlobalSearch: false,
        pagination: true,
        dataKey: 'transactionId',
        name: '',
        title: '',
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.searchTrasaction(req),
        },
        columns: [
            // {
            //     dataType: 'text',
            //     field: "transactionId",
            //     header: "lbl_sales_orders_status",
            //     sortable: true,
            //     filter: true,
            //     ...TawreedTableFilters.dropdown.dropdown("statusId", constants?.orderStatuses ?? [], 'value', 'label', 'in'),
            //     style: CssUtils.width('10%'),
            // },
            {
                dataType: 'numeric',
                field: "transactionId",
                header: "lbl_reports_statements_transactionid",
                sortable: true,
                filter: true,
                style: CssUtils.width('15%', CssUtils.color('var(--danger-color)')),
            },
            {
                field: "txnDate",
                header: "lbl_reports_statements_txndate",
                sortable: true,
                filter: true,
                ...TawreedTableFilters.date.range("txnDate", 'datetime'),
                style: CssUtils.width('15%'),
            },
            {
                dataType: 'text',
                field: "accountTitle",
                header: "lbl_reports_trialbalance_accounttitle",
                sortable: true,
                sortField: 'accountTitle',
                filter: true,
                filterField: 'accountTitle',
                style: CssUtils.width('15%', CssUtils.color('var(--success-color)')),
            },
            {
                dataType: 'text',
                field: "transactionTypeName",
                header: "lbl_reports_statements_type",
                sortable: true,
                sortField: 'transactionTypeName',
                filter: true,
                filterField: 'transactionTypeName',

                style: CssUtils.width('10%'),
            },
            
            {
                field: "creditAmount",
                header: "lbl_reports_statements_creditamount",
                sortable: true,
                filter: true,
                ...TawreedTableFormatters.decimal('creditAmount', user?.scale, 0, CssUtils.width('10%')),
                className: CssUtils.className.field.amount,
            },
            {
                field: "debitAmount",
                header: "lbl_reports_statements_debitamount",
                sortable: true,
                filter: true,
                ...TawreedTableFormatters.decimal('debitAmount', user?.scale, 0, CssUtils.width('10%')),
                className: CssUtils.className.field.amount,
            }
        ],
        toolbar: {
            // actions: data && data?.length > 0 ? [

            //     new TawreedAction(TawreedActionName.ReportsExport, 'stateless', 'lbl_reports_export_as_excel', PrimeIcons.FILE_EXCEL, () => print(xlsxReportURI!)),
            // ] :
             actions: [],
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                balanceId: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: balanceId,
                            matchMode: 'equals',
                        }
                    ]
                },
                partnerType: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: balanceId ? undefined : PartnerType.TopUp,
                            matchMode: 'equals',
                        }
                    ] 
                },
                txnDate: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: startDate,
                            matchMode: 'dateAfter',
                        },
                        {
                            value: endDate,
                            matchMode: 'dateBefore',
                        }
                    ]
                }
            }
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'orderId',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },

    }

    return (

        <Dialog maximizable resizable style={{ minWidth: '80%' }}
            header={<FinanceTransactionDialogHeader />}
            visible={visible} footer={<FinanceTransactionDialogFooter />} onHide={hide} draggable={false}
            position="top" closable={true} closeOnEscape={true}>
            <TawreedTable  {...tawreed} />
        </Dialog>
    )
}
