import { buildPaginatedRequest, PaginatedRequestParams, PaginatedResponse } from "../../../../../common/pagination";
import { HistoryTrackingItem, OrderRepository } from "../../data";
import { OrderDto, OrderItemDto } from "../dtos";
import { WindowUtils } from "../../../../../helpers/window";
import { DeliveryPartnerDto } from "../dtos/delivery-partner";
import { ConfirmReadyDto, ReadyToDeliveryDto } from "../dtos/ready-to-delivery";
import { FileService, TawreedFile } from "../../../../upload";
import { TransactionDocumentFilesDTO } from "../../../../finance/domain/dtos/transaction-document-file";
import { OrderFilesDTO } from "../dtos/order-documents";

class OrderService {

    private readonly repository: OrderRepository;
    private filesService = new FileService();

    constructor() {
        this.repository = new OrderRepository();
    }

    /**
     * Search
     * @param arg
     * @param returned
     * @returns
     */
    public search(arg: PaginatedRequestParams | string, returned?: boolean): Promise<PaginatedResponse<OrderDto>> {
        const request = typeof arg === 'string' ?
            buildPaginatedRequest({
                sort: { sortOrder: 1, sortField: 'partnerName', multiSortMeta: undefined },
                filters: {
                    orderId: {
                        operator: 'AND',
                        constraints: [
                            {
                                value: arg,
                                matchMode: "contains",
                            },
                        ],
                    },
                }
            })
            :
            buildPaginatedRequest({ ...arg, filters: { ...arg.filters } });
        return this.repository.search(request, returned);
    }

    /**
     * Details
     * @param id
     * @param returned
     * @returns
     */
    public getDetails(id: number, returned?: boolean): Promise<OrderDto> {
        return this.repository.getDetails(id, returned)
    };

    public getPublicDetails(id: string, lang: string): Promise<OrderDto>{
        return this.repository.getPublicDetails(id, lang);
    };

    /**
     * Confirm
     * @param orderId
     * @param carrierId
     * @returns {Promise<boolean>}
     */
    public confirm(orderId: number, carrierId: number): Promise<OrderDto> {
        return this.repository.confirm(orderId, carrierId);
    };
    public applyDiscount(orderId: number, type: string, value: number): Promise<OrderDto> {
        return this.repository.applyDiscount(orderId, type, value);
    };

    /**
     * Change Carrier
     * @param orderId
     * @param carrierId
     * @returns {Promise<boolean>}
     */
    public changeCarrier(orderId: number, carrierId: number): Promise<OrderDto> {
        return this.repository.changeCarrier(orderId, carrierId);
    };

    /**
     * Cancel
     * @param id
     * @param cancelReasonId
     * @returns
     */
    public cancel(id: number, reasonCode: number, reasonAr: string | undefined,
        reasonEn: string | undefined): Promise<OrderDto> {
        return this.repository.cancel(id, reasonCode, reasonAr, reasonEn);
    };

    /**
     * Cancel
     * @param id
     * @returns
     */
    public cancelReturn(id: number): Promise<OrderDto> {
        return this.repository.cancelReturn(id);
    };

    /**
     * Ship
     * @param id
     * @returns
     */
    public ship(id: number): Promise<OrderDto> {
        return this.repository.ship(id);
    };
    /**
    * ready
    * @param orderId
    * @param carrierId
    * @returns
    */
    public ready(readyToDeliveryDto: ReadyToDeliveryDto): Promise<OrderDto> {
        return this.repository.ready(readyToDeliveryDto);
    };

    public confirmAndReady(readyToDeliveryDto: ConfirmReadyDto): Promise<OrderDto> {
        return this.repository.confirmAndReady(readyToDeliveryDto);
    };

    
    public confirmReadyAndShip(readyToDeliveryDto: ConfirmReadyDto): Promise<OrderDto> {
        return this.repository.confirmReadyAndShip(readyToDeliveryDto);
    };
    public readyAndShip(readyToDeliveryDto: ConfirmReadyDto): Promise<OrderDto> {
        return this.repository.readyAndShip(readyToDeliveryDto);
    };



    /**
     * Deliver
     * @param id
     * @returns
     */
    public async deliver(data: any): Promise<OrderDto> {
        return this.repository.deliver(data)
    };

    public async confirmReadyShipAndDeliver(data: any): Promise<OrderDto>{
        return this.repository.confirmReadyShipAndDeliver(data)
    }
    public async readyShipAndDeliver(data: any): Promise<OrderDto>{
        return this.repository.readyShipAndDeliver(data)
    }
    public async shipAndDeliver(data: any): Promise<OrderDto>{
        return this.repository.shipAndDeliver(data)
    }
    public async addNote(data: any): Promise<OrderDto> {
        return this.repository.addNote(data);
    }
    public async checkDeliver(orderId:number): Promise<boolean>{
        return this.repository.checkDeliver(orderId);
    }

    /**
     *
     * @param returned
     * @param id
     */
    public return(returned: boolean | undefined, id: number, reasonCode: number, reasonAr: string | undefined,
        reasonEn: string | undefined): Promise<OrderDto> {
        return this.repository.return(returned, id, reasonCode, reasonAr, reasonEn);
    };

    /**
     * Print
     */
    public print(reportUrl: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                WindowUtils.print(reportUrl);
                resolve(true);
            } catch {
                reject();
            }
        });
    };

    public updateItem(item: OrderItemDto): Promise<OrderDto> {
        return this.repository.updateItem(item);
    };

    public getDeliveryPartners(salesOrderId: number): Promise<DeliveryPartnerDto[]> {
        return this.repository.getDeliveryPartners(salesOrderId);
    };
    public async createFile(file: TawreedFile, files: Array<TawreedFile>, title: string, orderId: number, type: string): Promise<boolean> {
        const filesKeys = [];
        for await (const el of files) {
            const key = await this.filesService.upload(el).then((res) => res)
            filesKeys.push(key);
        }
        var data: OrderFilesDTO = { orderId: orderId, files: [{ fileId: filesKeys[0], title: title, type: type, files: filesKeys }] }
        return this.repository.uploadOrderFile(data);

    }
    public async searchItems(id: number, arg: PaginatedRequestParams): Promise<PaginatedResponse<OrderItemDto>> {
        return this.repository.searchItems(id,arg);//.then(res => CustomerCartMapper.toDto(res));
    }

    public async getOrderHistory(arg: PaginatedRequestParams): Promise<PaginatedResponse<HistoryTrackingItem>> {
        return this.repository.getOrderHistory(arg);//.then(res => CustomerCartMapper.toDto(res));
    }
}


export const orderService = new OrderService();
