import React from "react";
import { DashboardDetailState, DashboardState, getDashboardInitialState } from "./dashboard.state";
import { GlobalContext } from "../../../../../context";
import { DashboardItemRoute, DashboardItemValue, DashboardService } from "../../../domain";
import { AuthUtils } from "../../../../auth/domain";
import { TawreedActionName } from "../../../../../common/actions";
import { tawreedTranslationService } from "../../../../../common/translations";
import { Routes } from "../../../../../router";
import { DashboardFullOrdersTotalChart } from "../dashboard-orders-total-chart";
import { DashboardPaymentModeOrdersChart } from "../dashboard-payment-mode-orders-chart";
import { OrdersDialog } from "../orders-dialog";


export const OrderDashboardComponent: React.FC = (_props) => {

    // di
    const { constants: { constants }, auth: { user } } = React.useContext(GlobalContext);
    const service = React.useMemo(() => new DashboardService(), []);

    // state
    const [state, setState] = React.useState<DashboardState>(getDashboardInitialState());
    const [detailState, setDetailState] = React.useState<DashboardDetailState>({
        startDate: undefined, endDate: undefined, customerIds: undefined,
         storeIds: undefined,
        paymentModeIds: undefined, priceListIds: undefined, statusIds: undefined, visible: false
    });
    const onHide = () =>{
        setDetailState({
            startDate: undefined, endDate: undefined, customerIds: undefined, storeIds: undefined,
            paymentModeIds: undefined, priceListIds: undefined, statusIds: undefined, visible: false
        })
    };
    const onDetailsPaymentModeOrders = React.useCallback((startDate: Date, endDate: Date, 
        paymentModeIds: number[] | undefined): void => {
            setDetailState({
                startDate: startDate, endDate: endDate, customerIds: undefined, storeIds: undefined,
                paymentModeIds: paymentModeIds, priceListIds: undefined, statusIds: undefined, visible: true
            })
        },[service]);
    const onDetailsFullOrders = React.useCallback((startDate: Date, endDate: Date, customerIds: number[] | undefined, storeIds: number[] | undefined,
        paymentModeIds: number[] | undefined, priceListIds: number[] | undefined, statusIds: number[] | undefined): void => {
            setDetailState({
                startDate: startDate, endDate: endDate, customerIds: customerIds, storeIds: storeIds,
                paymentModeIds: paymentModeIds, priceListIds: priceListIds, statusIds: statusIds, visible: true
            })
        },[service]);

    const onSearchFullOrders = React.useCallback((startDate: Date, endDate: Date, customerIds: number[] | undefined, storeIds: number[] | undefined,
        paymentModeIds: number[] | undefined, priceListIds: number[] | undefined, statusIds: number[] | undefined): void => {
        setState(previous => {
            return {
                ...previous,
                orders: {
                    ...previous.orders,
                    charts: {
                        ...previous.orders.charts,
                        totals: {
                            ...previous.orders.charts.totals,
                            loading: true,
                            totals: undefined
                        },
                    }
                },
            }
        });
        service.getOrdersChartsTotals(startDate, endDate, customerIds, storeIds, paymentModeIds, priceListIds,statusIds)
            .then(res => {
                setState(previous => {
                    return {
                        ...previous,
                        orders: {
                            ...previous.orders,
                            charts: {
                                ...previous.orders.charts,
                                totals: {
                                    ...previous.orders.charts.totals,
                                    loading: false,
                                    totals: res,
                                },
                            }
                        },
                    }
                });
            })
            .catch(() => {
                setState(previous => {
                    return {
                        ...previous,
                        orders: {
                            ...previous.orders,
                            charts: {
                                ...previous.orders.charts,
                                totals: {
                                    ...previous.orders.charts.totals,
                                    loading: false,
                                },
                            },
                        },
                    }
                });
            });
    }, [service]);

    const onSearchPaymentModeOrders = React.useCallback((startDate: Date, endDate: Date,
        paymentModeIds: number[] | undefined): void => {
        setState(previous => {
            return {
                ...previous,
                orders: {
                    ...previous.orders,
                    charts: {
                        ...previous.orders.charts,
                        paymentModes: {
                            ...previous.orders.charts.paymentModes,
                            loading: true,
                            totals: undefined
                        },
                    }
                },
            }
        });
        service.getPaymentModeOrdersChartsTotals(startDate, endDate, paymentModeIds)
            .then(res => {
                setState(previous => {
                    return {
                        ...previous,
                        orders: {
                            ...previous.orders,
                            charts: {
                                ...previous.orders.charts,
                                paymentModes: {
                                    ...previous.orders.charts.paymentModes,
                                    loading: false,
                                    modes: res,
                                },
                            }
                        },
                    }
                });
            })
            .catch(() => {
                setState(previous => {
                    return {
                        ...previous,
                        orders: {
                            ...previous.orders,
                            charts: {
                                ...previous.orders.charts,
                                totals: {
                                    ...previous.orders.charts.totals,
                                    loading: false,
                                },
                            },
                        },
                    }
                });
            });
    }, [service]);


    const valueCalculator = (e: DashboardItemValue): string => {
        if (e === undefined || e == null) {
            return '';
        } else if (typeof e === 'number') {
            return e.toString();
        } else if (typeof e === 'string') {
            return e;
        } else {
            if (constants && user) {
                return e(constants, user).toString();
            }
            return '';
        }
    }

    return (
        <div className="grid">
            <div className="col-12">
                <h3>{tawreedTranslationService.translate("lbl_order_dashboard")}</h3>
            </div>
            {
                //state.orders.charts.totals.canShow &&
                <React.Fragment>
                    <div className="col-12">
                        <DashboardFullOrdersTotalChart className=""
                            loading={state.orders.charts.totals.loading}
                            dto={state.orders.charts.totals.totals}
                            showDetails={onDetailsFullOrders}
                            calculator={valueCalculator} onSearch={onSearchFullOrders} />
                    </div>
                </React.Fragment>
            }
            {
                // state.orders.charts.totals.canShow &&
                <React.Fragment>
                    <div className="col-12">
                        <DashboardPaymentModeOrdersChart className=""
                            loading={state.orders.charts.paymentModes.loading}
                            dto={state.orders.charts.paymentModes.modes}
                            showDetails={onDetailsPaymentModeOrders}
                            calculator={valueCalculator} onSearch={onSearchPaymentModeOrders} />
                    </div>
                </React.Fragment>
            }
            <OrdersDialog
                statusIds={detailState.statusIds}
                storeIds={detailState.storeIds}
                customerIds={detailState.customerIds}
                visible={detailState.visible}
                startDate={detailState.startDate}
                endDate={detailState.endDate}
                priceListIds={detailState.priceListIds}
                paymentModeIds={detailState.paymentModeIds}
                xlsxReportURI=""
                hide={()=>onHide()}
            />

        </div>
    )
}
