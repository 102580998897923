import React from "react";
import { DashboardDetailState, DashboardState, getDashboardInitialState } from "./dashboard.state";
import { GlobalContext } from "../../../../../context";
import { DashboardItemRoute, DashboardItemValue, DashboardService } from "../../../domain";
import { tawreedTranslationService } from "../../../../../common/translations";
import { DashboardNewJoinersChart } from "../dashboard-new-joiners-chart";
import { DashboardPharmaciesPerGovernateChart } from "../dashboard-pharmacies-per-governate-chart";
import { DashboardTopOrderdPharmacies } from "../dashboard-top-ordered-pharmacies-chart";
import { Routes } from "../../../../../router";
import { DashboardInactivePharmaciesCard } from "../dashboard-inactive-pharmacies-card";
import { PartnersDialog } from "../partners-dialog";


export const PartnerDashboardComponent: React.FC = (_props) => {

    // di
    const { constants: { constants }, auth: { user } } = React.useContext(GlobalContext);
    const service = React.useMemo(() => new DashboardService(), []);

    // state
    const [state, setState] = React.useState<DashboardState>(getDashboardInitialState());

    const routeCalculator = (e: DashboardItemRoute): string => {
        if (e === undefined || e == null) {
            return '';
        } else if (typeof e === 'string') {
            return e.toString();
        } else {
            if (constants && user) {
                return e(constants, user);
            }
            return Routes.PartnerDashboard;
        }
    }
    const [partnerDetailState, setPartnerDetailState] = React.useState<DashboardDetailState>({
        startDate: undefined, endDate: undefined, statusIds: undefined, visible: false
    });
    const onHide = () => {
        setPartnerDetailState({
            startDate: undefined, endDate: undefined, statusIds: undefined, visible: false
        })
    };
    const onDetailsPharmaciesPerGovernate = React.useCallback((): void => {
            setPartnerDetailState({
            startDate: undefined, endDate: undefined, statusIds: undefined, visible: true
        })
    }, [service]);
    const onDetailsNewJoiners = React.useCallback((startDate: Date, endDate: Date,
        statusIds: number[] | undefined): void => {
            setPartnerDetailState({
            startDate: startDate, endDate: endDate, statusIds: statusIds, visible: true
        })
    }, [service]);
    
    const onSearchNewJoiners = React.useCallback((startDate: Date, endDate: Date, statusIds: number[] | undefined): void => {
        setState(previous => {
            return {
                ...previous,
                partners: {
                    ...previous.partners,
                    charts: {
                        ...previous.partners.charts,
                        newJoiners: {
                            ...previous.partners.charts.newJoiners,
                            loading: true,
                            totals: undefined
                        },
                    }
                },
            }
        });
        service.getNewJoinersChart(startDate, endDate, statusIds)
            .then(res => {
                setState(previous => {
                    return {
                        ...previous,
                        partners: {
                            ...previous.partners,
                            charts: {
                                ...previous.partners.charts,
                                newJoiners: {
                                    ...previous.partners.charts.newJoiners,
                                    loading: false,
                                    totals: res,
                                },
                            }
                        },
                    }
                });
            })
            .catch(() => {
                setState(previous => {
                    return {
                        ...previous,
                        partners: {
                            ...previous.partners,
                            charts: {
                                ...previous.partners.charts,
                                newJoiners: {
                                    ...previous.partners.charts.newJoiners,
                                    loading: false,
                                },
                            },
                        },
                    }
                });
            });
    }, [service]);

    const onSearchTopOrderedPharmacies = React.useCallback((startDate: Date, endDate: Date): void => {
        setState(previous => {
            return {
                ...previous,
                partners: {
                    ...previous.partners,
                    charts: {
                        ...previous.partners.charts,
                        TopOrderedPharmacies: {
                            ...previous.partners.charts.TopOrderedPharmacies,
                            loading: true,
                            totals: undefined
                        },
                    }
                },
            }
        });
        service.getTopOrderedPharmacies(startDate, endDate)
            .then(res => {
                setState(previous => {
                    return {
                        ...previous,
                        partners: {
                            ...previous.partners,
                            charts: {
                                ...previous.partners.charts,
                                TopOrderedPharmacies: {
                                    ...previous.partners.charts.TopOrderedPharmacies,
                                    loading: false,
                                    value: res,
                                },
                            }
                        },
                    }
                });
            })
            .catch(() => {
                setState(previous => {
                    return {
                        ...previous,
                        partners: {
                            ...previous.partners,
                            charts: {
                                ...previous.partners.charts,
                                TopOrderedPharmacies: {
                                    ...previous.partners.charts.TopOrderedPharmacies,
                                    loading: false,
                                },
                            },
                        },
                    }
                });
            });
    }, [service]);

    const onSearchInactivePharmacies = React.useCallback((startDate: Date, endDate: Date): void => {
        setState(previous => {
            return {
                ...previous,
                partners: {
                    ...previous.partners,
                    counts: {
                        ...previous.partners.counts,
                        loading: true,
                        value: undefined
                    }
                },
            }
        });
        service.getPartnerCount(startDate, endDate)
            .then(res => {
                setState(previous => {
                    return {
                        ...previous,
                        partners: {
                            ...previous.partners,
                            counts: {
                                ...previous.partners.counts,
                                loading: false,
                                value: res,
                            }
                        },
                    }
                });
            })
            .catch(() => {
                setState(previous => {
                    return {
                        ...previous,
                        partners: {
                            ...previous.partners,
                            counts: {
                                ...previous.partners.counts,
                                loading: false,
                                value: undefined
                            }
                        },
                    }
                });
            });
    }, [service]);

    React.useEffect((): void => {
        setState(previous => {
            return {
                ...previous,
                partners: {
                    ...previous.partners,
                    charts: {
                        ...previous.partners.charts,
                        charts: {
                            ...previous.partners.charts.pharmaciesPerGovernate,
                            loading: true,
                            value: undefined
                        },
                    }
                },
            }
        });
        service.getPharmaciesPerGovernateChartsCounts()
            .then(res => {
                setState(previous => {
                    return {
                        ...previous,
                        partners: {
                            ...previous.partners,
                            charts: {
                                ...previous.partners.charts,
                                pharmaciesPerGovernate: {
                                    ...previous.partners.charts.pharmaciesPerGovernate,
                                    loading: false,
                                    value: res,
                                },
                            }
                        },
                    }
                });
            })
            .catch(() => {
                setState(previous => {
                    return {
                        ...previous,
                        partners: {
                            ...previous.partners,
                            charts: {
                                ...previous.partners.charts,
                                charts: {
                                    ...previous.partners.charts.pharmaciesPerGovernate,
                                    loading: false,
                                    value: undefined
                                },
                            }
                        },
                    }
                });
            });
    }, [service]);


    const valueCalculator = (e: DashboardItemValue): string => {
        if (e === undefined || e == null) {
            return '';
        } else if (typeof e === 'number') {
            return e.toString();
        } else if (typeof e === 'string') {
            return e;
        } else {
            if (constants && user) {
                return e(constants, user).toString();
            }
            return '';
        }
    }

    return (
        <div className="grid">
            <div className="col-12">
                <h3>{tawreedTranslationService.translate("lbl_partner_dashboard")}</h3>
            </div>
            {
                // orders | counts | shipped
                //state.partners.counts.canShow.inactivePharmacie &&
                <div className="col-12 lg:col-12 xl:col-12">
                    <DashboardInactivePharmaciesCard loading={state.partners.counts.loading}
                        valueCalculator={valueCalculator}
                        routeCalculator={routeCalculator}
                        item={state.partners.counts.value?.InactivePharmacie}
                        onSearch={onSearchInactivePharmacies} />
                </div>
            }

            {
                //state.orders.charts.totals.canShow &&
                <React.Fragment>
                    <div className="col-12">
                        <DashboardNewJoinersChart className=""
                            loading={state.partners.charts.newJoiners.loading}
                            dto={state.partners.charts.newJoiners.totals}
                            calculator={valueCalculator} onSearch={onSearchNewJoiners}
                            onDetails={onDetailsNewJoiners} />
                    </div>
                </React.Fragment>
            }
            {
                //state.orders.charts.totals.canShow &&
                <React.Fragment>
                    <div className="col-12">
                        <DashboardPharmaciesPerGovernateChart className=""
                            loading={state.partners.charts.pharmaciesPerGovernate.loading}
                            //dto={state.partners.charts.pharmaciesPerGovernate.value}
                            calculator={valueCalculator}
                            onDeatils={onDetailsPharmaciesPerGovernate}
                            counts={state.partners.charts.pharmaciesPerGovernate.value} />
                    </div>
                </React.Fragment>
            }
            {
                //state.orders.charts.totals.canShow &&
                <React.Fragment>
                    <div className="col-12">
                        <DashboardTopOrderdPharmacies className=""
                            loading={state.partners.charts.TopOrderedPharmacies.loading}
                            //counts={state.partners.charts.pharmaciesPerGovernate.value}
                            calculator={valueCalculator}
                            onSearch={onSearchTopOrderedPharmacies}
                            counts={state.partners.charts.TopOrderedPharmacies.value} />
                    </div>
                </React.Fragment>
            }
            <PartnersDialog
            hide={()=>onHide()}
            visible={partnerDetailState.visible}
            startDate={partnerDetailState.startDate}
            endDate={partnerDetailState.endDate}
            statusIds={partnerDetailState.statusIds}
            />
        </div>
    )
}
