import React from "react";
import { useHistory } from "react-router";

import { PrimeIcons } from "primereact/api";

import { tawreedTranslationService } from "../../../../../common/translations";
import { Routes } from "../../../../../router";
import { fcm, messagesService, TawreedMessageCountDto, toastService } from "../../../domain";
import { GlobalContext } from "../../../../../context";
import { OverlayPanel } from "primereact/overlaypanel";
import { NotificationsDropdownList } from "./notifications-list";
import { Tab, Tabs, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import './notifications.scss';
import { AuthUtils } from "../../../../auth/domain";

declare type NotificationsTopbarButtonProps = {

    className: string;

    onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

export const NotificationsTopbarButton: React.FC<NotificationsTopbarButtonProps> = (props) => {

    const history = useHistory();
    const [value, setValue] = React.useState<number>(0);
    const [values, setValues] = React.useState<TawreedMessageCountDto[]>([]);
    const [text, setText] = React.useState<string>('');
    const { auth } = React.useContext(GlobalContext);
    const notifications = React.useRef<OverlayPanel>(null);
    var i = 0;

    const [activeIndex, setActiveIndex] = React.useState(0);

    const { constants: { constants } } = React.useContext(GlobalContext);

    const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        notifications.current?.hide();
        history.push(Routes.Notifications);
        if (event) {
            if (props.onClick) {
                props.onClick(event);
            }
        }
    }

    const onItemClick = () => {
        notifications.current?.hide();
        return Promise.resolve();
    }


    React.useEffect(
        () => {
            if (auth.user && auth.user.userId) {
                const unsubscribe1 = fcm.onMessageListener(res => {
                    if (res.data) {
                        // typeCode, messageId, recordId, click_action
                        const { title, body } = res.data;

                        toastService.show({ severity: "info", summary: title, detail: body });
                        messagesService.updateCount();
                        messagesService.updateCounts();
                    }
                });
                const unsubscribe2 = messagesService.getCount(res => setValue(res));
                const unsubscribe3 = messagesService.getCounts(res => setValues(res));

                return () => {
                    unsubscribe1();
                    unsubscribe2();
                    unsubscribe3();
                }
            }
        },
        [auth.user]);

    React.useEffect(
        () => {
            let mounted = true;
            let text = '';

            if (mounted) {
                if (!value) {
                    text = '';
                } else if (value < 10) {
                    text = value.toLocaleString();
                } else {
                    text = '+10';
                }
                setText(text);
            }
            return () => { mounted = false; };
        },
        [value]);
        React.useEffect(
            () => {
                i = 0;
            },
            [values]);

    const footer = (
        <React.Fragment>
            <div className="flex align-items-center justify-content-between grid">
                <div className="col-4 col-offset-5">
                    <a className="" onClick={onClick} href="#">{tawreedTranslationService.translate('lbl_notifications_all')}</a>
                </div>
            </div>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <button className={props.className}
                //onClick={onClick}
                onClick={(e) => { 
                    
                    messagesService.updateCounts();
                    notifications.current?.toggle(e)}}
                title={tawreedTranslationService.translate('lbl_notifications')}>
                <i className={PrimeIcons.BELL} />
                <span className="p-button-label p-c">{tawreedTranslationService.translate('lbl_notifications')}</span>
                {
                    text &&
                    text.length &&
                    <span className=" flex justify-content-center p-badge p-badge-danger">{text}</span>
                }
            </button>
            <OverlayPanel className="notifications" ref={notifications} style={{ width: '27vw' }} breakpoints={{ '960px': '60vw', '640px': '80vw' }}>

                {
                    <React.Fragment>
                        <Tabs
                isRTL={AuthUtils.current().language === "en" ? false : true}
                    activeTab={activeIndex}
                    onTabClick={(e, index) => {
                        setActiveIndex(index)
                    }}
                    showTabsScroll={true}
                    mode="scrollSelectedToCenterFromView"
                    hideNavBtns={true}
                >
                    <Tab key={"all"} className="menu-container">
                        <div className="p-link layout-topbar-button">
                            <span className="p-button-label p-c">{tawreedTranslationService.translate('lbl_all')}</span>
                            {
                                values &&
                                values.length > 0 &&
                                <span style={AuthUtils.current().language === "en" ?
                                    { marginTop: '-2rem', marginLeft: '100%', maxWidth:'1rem' }:
                                    { marginTop: '-2rem', marginRight: '100%', maxWidth:'1rem' }} className="flex justify-content-center p-badge p-badge-danger">
                                    {values.reduce((accumulator: number, currenttValue: any) => accumulator + currenttValue.messageCount, 0) ?? ' '}
                                </span> 
                            }
                        </div>
                    </Tab>
                    <Tab key={"public"} className="menu-container">
                        <div className="p-link layout-topbar-button">
                            <span className="p-button-label p-c">{tawreedTranslationService.translate('lbl_public')}</span>
                            {
                                values &&
                                values.length > 0 &&
                                <span style={AuthUtils.current().language === "en" ?
                                    { marginTop: '-2rem', marginLeft: '100%', maxWidth:'1rem' }:
                                    { marginTop: '-2rem', marginRight: '100%', maxWidth:'1rem' }} className="flex justify-content-center p-badge p-badge-danger">
                                    {values.reduce((accumulator: number, currenttValue: any) => 
                                        accumulator + ((currenttValue.typeCode === "PUBLIC" || 
                                            currenttValue.typeCode === "PROFILE" || 
                                            currenttValue.typeCode === "CUSTOMER" || 
                                            currenttValue.typeCode === "STORE" || 
                                            currenttValue.typeCode === "LOYALTY" || 
                                            currenttValue.typeCode === "BALANCE" || 
                                            currenttValue.typeCode === "RETURN_ORDER" || 
                                            currenttValue.typeCode === "CART") ? currenttValue.messageCount : 0), 0) ?? ' '}
                                </span>
                            }
                        </div>
                    </Tab>
                    <Tab key={"order"} className="menu-container">
                        <div className="p-link layout-topbar-button">
                            <span className="p-button-label p-c">{tawreedTranslationService.translate('lbl_order')}</span>
                            {
                                values &&
                                values.filter(value => value.typeCode === "ORDER").length > 0 &&
                                <span style={AuthUtils.current().language === "en" ?
                                    { marginTop: '-2rem', marginLeft: '100%', maxWidth:'1rem' }:
                                    { marginTop: '-2rem', marginRight: '100%', maxWidth:'1rem' }} className="flex justify-content-center p-badge p-badge-danger">
                                    {values.filter(value => value.typeCode === "ORDER").at(0)?.messageCount}
                                </span>
                            }
                        </div>
                    </Tab>
                    <Tab key={"wallet"} className="menu-container">
                        <div className="p-link layout-topbar-button">
                            <span className="p-button-label p-c">{tawreedTranslationService.translate('lbl_wallet')}</span>
                            {
                                values &&
                                values.filter(value => value.typeCode === "WALLET").length > 0 &&
                                <span style={AuthUtils.current().language === "en" ?
                                    { marginTop: '-2rem', marginLeft: '100%', maxWidth:'1rem' }:
                                    { marginTop: '-2rem', marginRight: '100%', maxWidth:'1rem' }} className="flex justify-content-center p-badge p-badge-danger">
                                    {values.filter(value => value.typeCode === "WALLET").at(0)?.messageCount}
                                </span>
                            }
                        </div>
                    </Tab>
                    <Tab key={"promotion"} className="menu-container">
                        <div className="p-link layout-topbar-button">
                            <span className="p-button-label p-c">{tawreedTranslationService.translate('lbl_promotion')}</span>
                            {
                                values &&
                                values.filter(value => value.typeCode === "PROMOTION").length > 0 &&
                                <span style={AuthUtils.current().language === "en" ?
                                    { marginTop: '-2rem', marginLeft: '100%', maxWidth:'1rem' }:
                                    { marginTop: '-2rem', marginRight: '100%', maxWidth:'1rem' }} className="flex justify-content-center p-badge p-badge-danger">
                                    {values.filter(value => value.typeCode === "PROMOTION").at(0)?.messageCount}
                                </span>
                            }
                        </div>
                    </Tab>
                    
                </Tabs>
                <TabScreen activeTab={activeIndex} index={0} key={"all"}>
                    <NotificationsDropdownList onItemClick={()=>onItemClick()} types={["all"]} />
                </TabScreen>
                <TabScreen activeTab={activeIndex} index={1} key={"public"}>
                    <NotificationsDropdownList onItemClick={()=>onItemClick()} types={["public","PROFILE","CUSTOMER","STORE","LOYALTY", "BALANCE", "RETURN_ORDER","CART"]} />
                </TabScreen>
                <TabScreen activeTab={activeIndex} index={2} key={"order"}>
                    <NotificationsDropdownList onItemClick={()=>onItemClick()} types={["order"]} />
                </TabScreen>
                <TabScreen activeTab={activeIndex} index={3} key={"wallet"}>
                    <NotificationsDropdownList onItemClick={()=>onItemClick()} types={["wallet"]} />
                </TabScreen>
                <TabScreen activeTab={activeIndex} index={4} key={"promotion"}>
                    <NotificationsDropdownList onItemClick={()=>onItemClick()} types={["promotion"]} />
                </TabScreen>
                
                    </React.Fragment>

                }

                {footer}
            </OverlayPanel>
        </React.Fragment>
    );
}
