import { buildPaginatedRequest, PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { WindowUtils } from "../../../../helpers/window";
import { OrderDto } from "../../../sales/orders/domain";
import { OrderDashboardRepository } from "../../data";
import { OrdersPerPaymentModeChartAllDto, OrdersTotalsChartDto } from "../dtos";

export class DashboardService {

    private readonly repository = new OrderDashboardRepository();


    public getOrdersChartsTotals(startDate: Date, endDate: Date,customerIds : number[] | undefined, 
        storeIds: number[]| undefined, paymentModeIds: number[]| undefined, 
        priceListIds: number[] | undefined, statusIds: number[] | undefined): Promise<OrdersTotalsChartDto> {
        return this.repository.getOrdersChartsTotals(startDate, endDate, customerIds, storeIds, paymentModeIds,
             priceListIds, statusIds);
    }
    public getPaymentModeOrdersChartsTotals(startDate: Date, endDate: Date,
        paymentModeIds: number[]| undefined): Promise<OrdersPerPaymentModeChartAllDto> {
        return this.repository.getPaymentModeOrdersChartsTotals(startDate, endDate, paymentModeIds);
    }

     public search(arg: PaginatedRequestParams | string, returned?: boolean): Promise<PaginatedResponse<OrderDto>> {
            const request = typeof arg === 'string' ?
                buildPaginatedRequest({
                    sort: { sortOrder: 1, sortField: 'partnerName', multiSortMeta: undefined },
                    filters: {
                        orderId: {
                            operator: 'AND',
                            constraints: [
                                {
                                    value: arg,
                                    matchMode: "contains",
                                },
                            ],
                        },
                    }
                })
                :
                buildPaginatedRequest({ ...arg, filters: { ...arg.filters } });
            return this.repository.search(request, returned);
        }
     public print(reportUrl: string): Promise<boolean> {
            return new Promise((resolve, reject) => {
                try {
                    WindowUtils.print(reportUrl);
                    resolve(true);
                } catch {
                    reject();
                }
            });
        };
}
