import React, { useEffect, useState } from "react";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { GlobalContext } from "../../../../../context";
import { PriceUtils } from "../../../../../helpers/price";
import { tawreedTranslationService } from "../../../../../common/translations";
import { TawreedFormField, TawreedInputNumber, TawreedMenuDropdown } from "../../../../../common";
import { TawreedMenuMultiSelect } from "../../../../../common/components/form/controls/multiselect/multiselect-menu";
import { DiscountTypesDropDown } from "../../../../catalog/store-products/presentaion/components/discount-types";
import { OrderItemDto } from "../../domain";
import { IBonusOperations } from "../../../../constants";
import { Bonus } from "../../../../catalog/store-products/data";
import { BonusDto } from "../../../../catalog/store-products/domain";
import { startTawreedDialog } from "../../../../../common/dialog";

export interface OrderItemProps {
    visible: boolean;

    hide: () => void;
    singlePrice?: number;
    appliedBonus?: BonusDto;
    accept: (data: { bonus: number, quantity: number, discountType?: string, bonusOperation?: string, sellingPrice?: number, bonusType?:string } | undefined) => void;
}

export const OrderItemChangeBonusDialog: React.FC<OrderItemProps> = ({visible, hide, accept, singlePrice, appliedBonus}) => {

      const [dialogState, setDialogState] = useState<{ quantity: number | undefined, bonus: number | undefined, discountType?: string, loading: boolean, bonusOperation?: string; bonusType?: string }>({
            quantity: undefined,
            bonus: undefined,
            loading: false,
            discountType: undefined,
            bonusType:undefined,
            bonusOperation: "EQUALS",
        });
        const [sellingPrice, setSellingPrice] = useState<number>(0);
        const [loader, setLoader] = useState<boolean>(false);
    
        const { auth:{user}, constants: { constants } } = React.useContext(GlobalContext); 
    
        useEffect(() => {
            setDialogState({
                quantity: appliedBonus ? appliedBonus.quantity : undefined,
                bonus: appliedBonus ? appliedBonus.bonus : undefined,
                loading: false,
                discountType: appliedBonus ? appliedBonus.discountType : undefined,
                bonusOperation: appliedBonus ? appliedBonus.bonusOperation : undefined,
                bonusType: appliedBonus ? appliedBonus.bonusType : undefined,
            })
        }, [visible])
        const priceUtils: PriceUtils =  new PriceUtils();
        useEffect(()=>{
            let time = setTimeout(() => {
                setLoader(true);
                priceUtils.getSellingPrice(singlePrice ?? 0, dialogState.discountType ?? 'fixed', dialogState.bonus ?? 0, user?.scale!)
                .then((res)=>{
                setLoader(false);
                setSellingPrice(res);
            }).catch((res)=>{
                setLoader(false);
            });
            }, 500)
    
            return () => clearTimeout(time);
        },[dialogState.discountType, dialogState.bonus, singlePrice]);
    
    
        const BonusDialogFooter = () => {
            return (<div>
                <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={dialogState.loading} />
                <Button onClick={() => {
                    accept({ bonus: dialogState.bonus!, quantity: dialogState.quantity!,
                         discountType: dialogState.discountType ?? 'fixed', bonusOperation: dialogState.bonusOperation ?? IBonusOperations.EQUALS,
                          sellingPrice:sellingPrice, bonusType: dialogState.bonusType});
                          hide();
                        }} label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" disabled={!dialogState || dialogState.loading || !dialogState.bonus || !dialogState.quantity || (dialogState.bonusType==='DISCOUNT' && sellingPrice <= 0)}
                    loading={dialogState.loading} className="p-button-text"/>
                    {
                        appliedBonus && 
                        <Button onClick={() => {
                            return new Promise<void>((resolve, reject) => {
                            startTawreedDialog({
                                                    // message: tawreedTranslationService.translate('tawreed_dialog_confirm_delete_msg'),
                                                    header: tawreedTranslationService.translate('tawreed_dialog_confirm_delete_hdr'),
                                                    icon: 'pi pi-info-circle',
                                                    acceptClassName: 'p-button-danger',
                                                    rejectClassName: 'p-button-danger p-button-text',
                                                    accept: () => {accept(undefined); hide()},
                                                });
                            });}} label={tawreedTranslationService.translate('lbl_delete')} icon="pi pi-check" loading={dialogState.loading} className="p-button-text p-button-danger"/>
                    }
                
            </div>);
        }
        return (
            <Dialog maximizable resizable style={{ minWidth: '70%' }} header={tawreedTranslationService.translate('lbl_offer')} visible={visible} footer={<BonusDialogFooter />} onHide={hide} draggable={false} position="top" closable={true} closeOnEscape={true}>
                <form className="formgrid grid" >
                {!appliedBonus && 
                <TawreedFormField className="field col-12 lg:col-12 md:col-12" name="bonusOperation" title="lbl_bonus_operation">
                                <TawreedMenuDropdown menu={"bonusTypes"} value={dialogState.bonusType} name="bonusOperation" render="standalone" rules={{ required: 'msg_field_is_required' }} onChange={(e) => {
                                    setDialogState( { ...dialogState, bonusType: e.value, bonus: undefined, quantity: undefined });
                                }} />
                            </TawreedFormField>
}
                    { dialogState.bonusType === "EXTRA_ITEMS" &&
                        <React.Fragment>
                            <TawreedFormField name="quantity" title="lbl_store_products_bonus_qty" className="field col-12 md:col-6">
                                <TawreedInputNumber value={dialogState?.quantity} name="quantity" rules={{ required: "msg_field_is_required" }} render="standalone" onChange={(e) => {
                                    setDialogState({ ...dialogState, quantity: e.value ? e.value : 0 });
                                }} />
                            </TawreedFormField>
                            <TawreedFormField name="bonus" title={"lbl_store_products_bonus"} className="field col-12 md:col-6">
                                <TawreedInputNumber mode={undefined} value={dialogState?.bonus} name="bonus" rules={{ required: "msg_field_is_required" }} render="standalone" onChange={(e) => {
                                    setDialogState({ ...dialogState, bonus: e.value ? e.value : 0 });
                                }} />
                            </TawreedFormField>
                        </React.Fragment>
                    }
                    {dialogState.bonusType === "DISCOUNT" &&
                        <React.Fragment>
                            <TawreedFormField name="quantity" title="lbl_store_products_bonus_qty" className="field col-3 lg:col-3 md:col-6">
                                <TawreedInputNumber value={dialogState?.quantity} name="quantity" rules={{ required: "msg_field_is_required" }} render="standalone" onChange={(e) => {
                                    setDialogState({ ...dialogState, quantity: e.value ? e.value : 0 });
                                }} />
                            </TawreedFormField>
                            <TawreedFormField className="field col-3 lg:col-3 md:col-6" name="bonusOperation" title="lbl_bonus_operation">
                                <TawreedMenuDropdown menu={"bonusOperations"} value={dialogState?.bonusOperation} name="bonusOperation" render="standalone" rules={{ required: 'msg_field_is_required' }} onChange={(e) => {
                                    setDialogState({ ...dialogState, bonusOperation: e.value })
                                }} />
                            </TawreedFormField>
                            <TawreedFormField className="field col-3 lg:col-3 md:col-6" name="discountType" title="lbl_price_scheme_discountType">
                                <DiscountTypesDropDown value={dialogState?.discountType} name="discountType" render="standalone" rules={{ required: 'msg_field_is_required' }} onChange={(e) => {
                                    setDialogState({ ...dialogState, discountType: e.value })
                                }} />
                            </TawreedFormField>
                            <TawreedFormField name="bonus" title={"lbl_store_products_discount_bonus_val"} className="field col-3 lg:col-3 md:col-6">
                                <TawreedInputNumber mode={'decimal'} value={dialogState?.bonus} name="bonus" rules={{ required: "msg_field_is_required" }} render="standalone" onChange={(e) => {
                                    setDialogState({ ...dialogState, bonus: e.value ? e.value : 0 });
                                }} />
                            </TawreedFormField>
    
                            {(sellingPrice >= 0) ?
                                <React.Fragment>
                                    <TawreedFormField name="sellingPrice" title="lbl_sellingPrice_befor_per_unit" className="field col-6 lg:col-6 md:col-6 mt-2">
                                        <TawreedInputNumber mode="decimal" disabled suffix=" JOD" value={singlePrice} name="sellingPrice" render="standalone" readOnly />
                                    </TawreedFormField>
                                    <TawreedFormField name="sellingPrice" title="lbl_sellingPrice_befor_after_unit" className="field col-6 lg:col-6 md:col-6 mt-2">
                                    <span>
                             {loader && <i className="pi pi-spinner pi-spin ml-2"></i>}
                               
                             <TawreedInputNumber mode="decimal" disabled suffix=" JOD" value={sellingPrice} name="sellingPrice" render="standalone" readOnly />
                                    
    
                                </span>
                                    </TawreedFormField>
                                </React.Fragment>
                                : ''
                            }
                        </React.Fragment>
                    }
                </form>
            </Dialog>
        )
}
