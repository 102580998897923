import { JObject } from "../../../../common/data/models";
import { DateUtils } from "../../../../helpers/date";
import { PaymentPartnerOnboard } from "../../domain";

export const PaymentPartnerOnboardMapper = {

    fromJson(obj: JObject): PaymentPartnerOnboard {
        return {
            address: obj.address,
            customerName: obj.customerName,
            establishmentNationalId: obj.establishmentNationalId,
            limit: obj.limit,
            mobile: obj.mobile,
            name: obj.name,
            nationalId: obj.nationalId,
            partnerId: obj.partnerId,
            paymentPartnerId: obj.paymentPartnerId,
            paymentPartnerOnboardId: obj.paymentPartnerOnboardId,
            paymentPartnerTitleAr: obj.paymentPartnerTitleAr,
            paymentPartnerTitleEn: obj.paymentPartnerTitleEn,
            siteId: obj.siteId,
            status: obj.status,
            commercialRecordForm: obj.commercialRecordForm,
            crifForm: obj.crifForm,
            idImage: obj.idImage,
            reference: obj.reference,
            sixMonthStatement: obj.sixMonthStatement,
            createdAt:DateUtils.fromISO(obj.createdAt)
        };
    },
}