import { JObject } from "../../../../common/data/models";
import { DateUtils } from "../../../../helpers/date";
import { TransactionDto } from "../../domain/dtos/Transaction";

export const TransactionMapper = {

    toDto(json: JObject): TransactionDto{
        return {
            accountTitle: json.accountTitle,
            balanceId: json.balanceId,
            creditAmount: json.creditAmount,
            debitAmount: json.debitAmount,
            partnerId: json.partnerId,
            transactionId: json.transactionId,
            transactionTypeName: json.transactionTypeName,
            transactionTypId: json.transactionTypeId,
            txnDate: DateUtils.fromISO(json?.txnDate)
        };
    }
}