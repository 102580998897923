import { OrdersPerPaymentModeChartAllDto, OrdersTotalsChartDto } from "../../../domain";

export type DashboardState = {
    /**
     * orders
     */
    orders: {
        /**
         * charts
         */
        charts: {

            totals: {
                canShow: boolean;

                /**
                 * loading
                 */
                loading: boolean;

                /**
                 * totals
                 */
                totals: OrdersTotalsChartDto | undefined;
            };

            paymentModes: {
                canShow: boolean;

                /**
                 * loading
                 */
                loading: boolean;

                /**
                 * modes
                 */
                modes: OrdersPerPaymentModeChartAllDto | undefined;
            };
        }
    };
}
export type DashboardDetailState = {
    startDate: Date| undefined,
    endDate: Date| undefined,
    customerIds: number[] | undefined,
    storeIds: number[] | undefined,
    paymentModeIds: number[] | undefined,
    priceListIds: number[] | undefined, 
    statusIds: number[] | undefined,
    visible: boolean,
}
export function getDashboardInitialState(): DashboardState {
    return {
        orders: {
            charts: {
                totals: {
                    canShow: false,
                    loading: false,
                    totals: undefined,
                },
                paymentModes: {
                    canShow: false,
                    loading: false,
                    modes: undefined,
                },
            },
        },
    }
}
