import { Dispatch } from "react";
import { endpoints } from "../../constants/endpoints";
import { tawreedHttpService } from "../../http";
import { ConstantsAction } from "./reducer";
import { IStockSource, IStockType, IStoreProductRawStats, TawreedConstants } from "./state";
import { tawreedTranslationService } from "../../common/translations";
import { OrderInvoiceTypes, PartnerType, WalletStatus } from "../partners/data";
import { AuthUtils } from "../auth/domain";
import { JObject } from "../../common/data/models";
import { RoleMapper } from "../users/data/mappers/role";

export class ConstantsActions {

    getConstants(dispatch: Dispatch<ConstantsAction>): void {

        dispatch({ type: 'CONSTANTS_LOADING' });
        tawreedHttpService.post<JObject, JObject>(endpoints.GET_CONSTANTS, {}, undefined, undefined, 'error')
            .then(res => {
                console.log(res);
                if (!res || !res.data) {
                    throw new Error('response data undefined');
                }
                const lang = AuthUtils.current().language;
                dispatch({
                    type: 'CONSTANTS_SUCCESS',
                    payload: {
                        ...res.data as TawreedConstants,
                        walletStatus: [
                            {
                                id: 0,
                                value: WalletStatus.Pendding,
                                label: tawreedTranslationService.translate('lbl_wallet_status_Pending'),
                                type: 'wallet',
                                langCode: lang,
                            },
                            {
                                id: 1,
                                value: WalletStatus.InActive,
                                label: tawreedTranslationService.translate('lbl_wallet_status_Inactive'),
                                type: 'wallet',
                                langCode: lang,
                            },
                            {
                                id: 2,
                                value: WalletStatus.Active,
                                label: tawreedTranslationService.translate('lbl_wallet_status_Active'),
                                type: 'wallet',
                                langCode: lang,
                            }
                        ],
                        canShippingOptions: [
                            {
                                id: 1,
                                value: true,
                                label: tawreedTranslationService.translate('accept'),
                                type: 'shipping',
                                langCode: lang,
                            },
                            {
                                id: 2,
                                value: false,
                                label: tawreedTranslationService.translate('reject'),
                                type: 'shipping',
                                langCode: lang,
                            }
                        ],
                        orderDocumentTypes: [
                            {
                                id: 1,
                                value: OrderInvoiceTypes.Invoice,
                                label: tawreedTranslationService.translate('lbl_reports_invoice'),
                                type: 'orderDocumentType',
                                langCode: lang,
                            },
                            {
                                id: 2,
                                value: OrderInvoiceTypes.Other,
                                label: tawreedTranslationService.translate('lbl_other'),
                                type: 'orderDocumentType',
                                langCode: lang,
                            }
                        ],
                        partnerTypes: [
                            // {
                            //     id: 0,
                            //     value: PartnerType.Operation,
                            //     label: tawreedTranslationService.translate('lbl_partner_0'),
                            //     type: 'OPERATION',
                            //     langCode: lang,
                            // },
                            {
                                id: 1,
                                value: PartnerType.Store,
                                label: tawreedTranslationService.translate('lbl_partner_1'),
                                type: 'STORE',
                                langCode: lang,
                            },
                            {
                                id: 2,
                                value: PartnerType.Customer,
                                label: tawreedTranslationService.translate('lbl_partner_2'),
                                type: 'CUSTOMER',
                                langCode: lang,
                            },
                            {
                                id: 3,
                                value: PartnerType.Delivery,
                                label: tawreedTranslationService.translate('lbl_partner_3'),
                                type: 'DELIVERY',
                                langCode: lang,
                            },
                            {
                                id: 4,
                                value: PartnerType.TopUp,
                                label: tawreedTranslationService.translate('lbl_partner_4'),
                                type: 'TOPUP',
                                langCode: lang,
                            },
                            {
                                id: 5,
                                value: PartnerType.Platform,
                                label: tawreedTranslationService.translate('lbl_partner_5'),
                                type: 'CUSTOMER',
                                langCode: lang,
                            },
                            {
                                id: 6,
                                value: PartnerType.WalletPartner,
                                label: tawreedTranslationService.translate('lbl_partner_6'),
                                type: 'DELIVERY',
                                langCode: lang,
                            },
                            {
                                id: 7,
                                value: PartnerType.Payment,
                                label: tawreedTranslationService.translate('lbl_partner_7'),
                                type: 'TOPUP',
                                langCode: lang,
                            },
                        ],
                        productCategories: res.data.productCategories ? res.data.productCategories.map((e: any) => {
                            return {
                                id: e.categoryId,
                                value: e.categoryId,
                                label: e.categoryName,
                                type: 'product_categories',
                                langCode: e.langCode,
                            };
                        }) : [],
                        businessTypesFilesStore: res.data.businessTypesFilesStore ? res.data.businessTypesFilesStore.map((el: any) => {
                            return {
                                id: el.businessTypeId,
                                label: el.businessName,
                                type: 'business types files store',
                                value: el.files,
                                langCode: '',
                            }
                        }) : [],
                        businessTypesFilesCustomer: res.data.businessTypesFilesCustomer ? res.data.businessTypesFilesCustomer.map((el: any) => {
                            return {
                                id: el.businessTypeId,
                                label: el.businessName,
                                type: 'business types files Customer',
                                value: el.files,
                                langCode: '',
                            }
                        }) : [],
                        businessTypesStore: res.data.businessTypesStore ? res.data.businessTypesStore.map((el: any) => {
                            return {
                                id: el.businessTypeId,
                                label: el.businessName,
                                type: 'BUSINESS_TYPE_STORE',
                                value: el.businessTypeId,
                                langCode: lang,
                            }
                        }) : [],
                        businessTypesCustomer: res.data.businessTypesCustomer ? res.data.businessTypesCustomer.map((el: any) => {
                            return {
                                id: el.businessTypeId,
                                label: el.businessName,
                                type: 'BUSINESS_TYPE_CUSTOMER',
                                value: el.businessTypeId,
                                langCode: lang,
                            }
                        }) : [],
                        businessTypesTopUp: res.data.businessTypesTopUp ? res.data.businessTypesTopUp.map((el: any) => {
                            return {
                                id: el.businessTypeId,
                                label: el.businessName,
                                type: 'BUSINESS_TYPE_TOPUP',
                                value: el.businessTypeId,
                                langCode: lang,
                            }
                        }) : [],
                        nationality: res.data.nationalityList ? res.data.nationalityList.map((el: any) => {
                            return {
                                id: el.nationalityId,
                                label: el.name,
                                type: 'nationality',
                                value: el.value,
                                langCode: '',
                            }
                        }) : [],
                        invoiceDiscountTypes: [
                            {
                                id: 1,
                                value: tawreedTranslationService.translate('lbl_discountType_1_value').toLowerCase(),
                                label: tawreedTranslationService.translate('lbl_discountType_1_label'),
                                type: 'DISCOUNT',
                                langCode: lang,
                            },
                            {
                                id: 2,
                                value: tawreedTranslationService.translate('lbl_discountType_2_value').toLowerCase(),
                                label: tawreedTranslationService.translate('lbl_discountType_2_label'),
                                type: 'DISCOUNT',
                                langCode: lang,
                            },
                        ],
                        discountTypes: [
                            {
                                id: 1,
                                value: tawreedTranslationService.translate('lbl_discountType_1_value').toLowerCase(),
                                label: tawreedTranslationService.translate('lbl_discountType_1_label'),
                                type: 'DISCOUNT',
                                langCode: lang,
                            },
                            {
                                id: 2,
                                value: tawreedTranslationService.translate('lbl_discountType_2_value').toLowerCase(),
                                label: tawreedTranslationService.translate('lbl_discountType_2_label'),
                                type: 'DISCOUNT',
                                langCode: lang,
                            },
                            {
                                id: 3,
                                value: tawreedTranslationService.translate('lbl_discountType_3_value').toLowerCase(),
                                label: tawreedTranslationService.translate('lbl_discountType_3_label'),
                                type: 'DISCOUNT',
                                langCode: lang,
                            },
                        ],
                        discountTypesBasics: [
                            {
                                id: 1,
                                value: tawreedTranslationService.translate('lbl_discountType_1_value').toLowerCase(),
                                label: tawreedTranslationService.translate('lbl_discountType_1_label'),
                                type: 'DISCOUNT',
                                langCode: lang,
                            },
                            {
                                id: 2,
                                value: tawreedTranslationService.translate('lbl_discountType_2_value').toLowerCase(),
                                label: tawreedTranslationService.translate('lbl_discountType_2_label'),
                                type: 'DISCOUNT',
                                langCode: lang,
                            },
                        ],
                        importHelperStatus: [
                            {
                                id: 1,
                                value: tawreedTranslationService.translate('lbl_import_helper_status1_value').toString(),
                                label: tawreedTranslationService.translate('lbl_import_helper_status1_label'),
                                type: 'IMPORT_HELPER_STATUS',
                                langCode: lang,
                            },
                            {
                                id: 2,
                                value: tawreedTranslationService.translate('lbl_import_helper_status2_value').toString(),
                                label: tawreedTranslationService.translate('lbl_import_helper_status2_label'),
                                type: 'IMPORT_HELPER_STATUS',
                                langCode: lang,
                            },
                            {
                                id: 3,
                                value: tawreedTranslationService.translate('lbl_import_helper_status3_value').toString(),
                                label: tawreedTranslationService.translate('lbl_import_helper_status3_label'),
                                type: 'IMPORT_HELPER_STATUS',
                                langCode: lang,
                            }, {
                                id: 4,
                                value: tawreedTranslationService.translate('lbl_import_helper_status4_value').toString(),
                                label: tawreedTranslationService.translate('lbl_import_helper_status4_label'),
                                type: 'IMPORT_HELPER_STATUS',
                                langCode: lang,
                            },
                        ],
                        storeRawStatus: [
                            {
                                id: 1,
                                label: tawreedTranslationService.translate('lbl_store_raw_status1_label').toString(),
                                value: IStoreProductRawStats.EXACT_MATCH,
                                type: 'STORE_RAW_STATUS',
                                langCode: lang,
                            },
                            {
                                id: 2,
                                label: tawreedTranslationService.translate('lbl_store_raw_status2_label').toString(),
                                value: IStoreProductRawStats.HIGH_SIMILARITY,
                                type: 'STORE_RAW_STATUS',
                                langCode: lang,
                            },
                            {
                                id: 3,
                                label: tawreedTranslationService.translate('lbl_store_raw_status3_label').toString(),
                                value: IStoreProductRawStats.MEDIUM_SIMILARITY,
                                type: 'STORE_RAW_STATUS',
                                langCode: lang,
                            },
                            {
                                id: 4,
                                label: tawreedTranslationService.translate('lbl_store_raw_status4_label').toString(),
                                value: IStoreProductRawStats.LOW_SIMILARITY,
                                type: 'STORE_RAW_STATUS',
                                langCode: lang,
                            },
                            {
                                id: 5,
                                label: tawreedTranslationService.translate('lbl_store_raw_status5_label').toString(),
                                value: IStoreProductRawStats.NO_MATCH,
                                type: 'STORE_RAW_STATUS',
                                langCode: lang,
                            },
                        ],
                        storeRawConfirmStatus: [
                            {
                                id: 1,
                                label: tawreedTranslationService.translate('lbl_store_raw_confirm_status1').toString(),
                                value: true,
                                type: 'STORE_RAW_CONFIRM_STATUS',
                                langCode: lang,
                            },
                            {
                                id: 2,
                                label: tawreedTranslationService.translate('lbl_store_raw_confirm_status2').toString(),
                                value: false,
                                type: 'STORE_RAW_CONFIRM_STATUS',
                                langCode: lang,
                            },
                        ],
                        checkOutModes: [
                            {
                                id: 0,
                                value: 0,
                                label: tawreedTranslationService.translate('lbl_checkout_mode_place'),
                                type: 'CHEKOUT_MODE',
                                langCode: lang,
                            },
                            {
                                id: 1,
                                value: 1,
                                label: tawreedTranslationService.translate('lbl_checkout_mode_notify'),
                                type: 'CHEKOUT_MODE',
                                langCode: lang,
                            },

                        ],
                        priceLists: [
                            {
                                id: 0,
                                value: 1,
                                label: tawreedTranslationService.translate('lbl_price_list_default'),
                                type: 'PRICE_LIST',
                                langCode: lang,
                            },
                            {
                                id: 1,
                                value: 2,
                                label: tawreedTranslationService.translate('lbl_price_list_credit'),
                                type: 'PRICE_LIST',
                                langCode: lang,
                            },

                        ],
                        productCategoriesFields: res.data.productCategoriesFields ?? [],
                        cities: res.data.cities ?? [],
                        classifications: res.data.classifications?.map((e: any) => { return { id: e.id, label: e.label, langCode: e.langCode, value: e.id } }) ?? [],
                        paymentModeWithPriceLists: res.data.paymentModes?.map((e: any) => { return { paymentModeId: e.paymentModeId, titleEn: e.titleEn, titleAr: e.titleAr,priceListId: e.priceListId, payThroughUS: e.payThroughUS } }) ?? [],
                        paymentModes: res.data.paymentModes?.map((e: any) => { return { id: e.paymentModeId, label: e.langCode === 'en' ? e.titleEn : e.titleAr, langCode: e.langCode, type: 'PAYMENT_MODE', value: e.paymentModeId } }) ?? [],
                        //paymentModeWithPriceLists: res.data.paymentMethods?.map((e: any) => { return { paymentModeId: e.paymentModeId, titleEn: e.titleEn, titleAr: e.titleAr,priceListId: e.priceListId, payThroughUS: e.payThroughUS } }) ?? [],
                        cartTypes: [{
                            id: 1,
                            value: 1,
                            label: tawreedTranslationService.translate('lbl_sales_cart_types_1'),
                            type: 'CART_TYPE',
                            langCode: lang,
                        },
                        {
                            id: 2,
                            value: 2,
                            label: tawreedTranslationService.translate('lbl_sales_cart_types_2'),
                            type: 'CART_TYPE',
                            langCode: lang,
                        },
                        {
                            id: 3,
                            value: 3,
                            label: tawreedTranslationService.translate('lbl_sales_cart_types_3'),
                            type: 'CART_TYPE',
                            langCode: lang,
                        },],
                        transactionStatuses: [
                            {
                                id: 1,
                                value: 1,
                                label: tawreedTranslationService.translate('lbl_topup_txn_txnstatus_1'),
                                type: 'TXN_STATUSES',
                                langCode: lang,
                            },
                            {
                                id: 2,
                                value: 2,
                                label: tawreedTranslationService.translate('lbl_topup_txn_txnstatus_2'),
                                type: 'TXN_STATUSES',
                                langCode: lang,
                            },
                            {
                                id: 3,
                                value: 3,
                                label: tawreedTranslationService.translate('lbl_topup_txn_txnstatus_3'),
                                type: 'TXN_STATUSES',
                                langCode: lang,
                            },
                        ],
                        paymentOrderStatuses: [
                            {
                                id: 0,
                                value: 0,
                                label: 'INITIATED',//tawreedTranslationService.translate('lbl_payment_order_status_1'),
                                type: 'INITIATED',
                                langCode: lang,
                            },
                            {
                                id: 1,
                                value: 1,
                                label: 'AMOUNT_BLOCKED',//tawreedTranslationService.translate('lbl_payment_order_status_2'),
                                type: 'AMOUNT_BLOCKED',
                                langCode: lang,
                            },
                            {
                                id: 2,
                                value: 2,
                                label: 'PAID',//tawreedTranslationService.translate('lbl_payment_order_status_3'),
                                type: 'PAID',
                                langCode: lang,
                            }, {
                                id: 3,
                                value: 3,
                                label: 'CANCEL',//tawreedTranslationService.translate('lbl_payment_order_status_4'),
                                type: 'CANCEL',
                                langCode: lang,
                            },
                        ],
                        paymentransactionStatuses: [
                            {
                                id: 3,
                                value: 3,
                                label: 'CANCELLED',//tawreedTranslationService.translate('lbl_payment_txn_txnstatus_1'),
                                type: 'CANCELLED',
                                langCode: lang,
                            },
                            {
                                id: 4,
                                value: 4,
                                label: 'COMPLETED',//tawreedTranslationService.translate('lbl_payment_txn_txnstatus_1'),
                                type: 'COMPLETED',
                                langCode: lang,
                            },
                            {
                                id: 5,
                                value: 5,
                                label: 'FAILED',//tawreedTranslationService.translate('lbl_payment_txn_txnstatus_1'),
                                type: 'FAILED',
                                langCode: lang,
                            },
                            // {
                            //     id: 0,
                            //     value: 0,
                            //     label: 'OTP_SENT',//tawreedTranslationService.translate('lbl_payment_txn_txnstatus_1'),
                            //     type: 'OTP_SENT',
                            //     langCode: lang,
                            // },
                            // {
                            //     id: 1,
                            //     value: 1,
                            //     label: 'AMOUNT_RELEASED',//tawreedTranslationService.translate('lbl_payment_txn_txnstatus_2'),
                            //     type: 'AMOUNT_RESERVED',
                            //     langCode: lang,
                            // },
                            // {
                            //     id: 2,
                            //     value: 2,
                            //     label: 'AMOUNT_RELEASED',
                            //     type: 'AMOUNT_RELEASED',
                            //     langCode: lang,
                            // },
                            // {
                            //     id: 3,
                            //     value: 3,
                            //     label: 'CANCELLED',
                            //     type: 'CANCELLED',
                            //     langCode: lang,
                            // },
                            // {
                            //     id: 4,
                            //     value: 4,
                            //     label: 'INITIATED',
                            //     type: 'INITIATED',
                            //     langCode: lang,
                            // },
                        ],
                        paymentTransactionType: [
                            {
                                id: 0,
                                value: 0,
                                label: 'INITIATE',
                                type: 'INITIATE',
                                langCode: lang,
                            },
                            {
                                id: 1,
                                value: 1,
                                label: 'BLOCK',
                                type: 'BLOCK',
                                langCode: lang,
                            },
                            {
                                id: 2,
                                value: 2,
                                label: 'RELEASE',
                                type: 'RELEASE',
                                langCode: lang,
                            },
                            {
                                id: 3,
                                value: 3,
                                label: 'RELEASETOBUYER',
                                type: 'RELEASETOBUYER',
                                langCode: lang,
                            },
                            {
                                id: 4,
                                value: 4,
                                label: 'RELEASETOSELLER',
                                type: 'RELEASETOSELLER',
                                langCode: lang,
                            },
                            {
                                id: 5,
                                value: 5,
                                label: 'RELEASETOMAWRID',
                                type: 'RELEASETOMAWRID',
                                langCode: lang,
                            },
                            {
                                id: 6,
                                value: 6,
                                label: 'CANCEL',
                                type: 'CANCEL',
                                langCode: lang,
                            },
                        ],
                        paymentTransactionTypes: [
                            {
                                id: 1,
                                value: 1,
                                label: tawreedTranslationService.translate('lbl_payment_txn_txntype_1'),
                                type: 'ORDER',
                                langCode: lang,
                            },
                            {
                                id: 2,
                                value: 2,
                                label: tawreedTranslationService.translate('lbl_payment_txn_txntype_2'),
                                type: 'RETURN_ORDER',
                                langCode: lang,
                            },
                            {
                                id: 3,
                                value: 3,
                                label: tawreedTranslationService.translate('lbl_payment_txn_txntype_3'),
                                type: 'PAYMENT_VOUCHER',
                                langCode: lang,
                            },
                            {
                                id: 4,
                                value: 4,
                                label: tawreedTranslationService.translate('lbl_payment_txn_txntype_4'),
                                type: 'RECEIPT_VOUCHER',
                                langCode: lang,
                            },
                            {
                                id: 5,
                                value: 5,
                                label: tawreedTranslationService.translate('lbl_payment_txn_txntype_5'),
                                type: 'CREDIT_NOTE',
                                langCode: lang,
                            },
                            {
                                id: 6,
                                value: 6,
                                label: tawreedTranslationService.translate('lbl_payment_txn_txntype_6'),
                                type: 'DEBIT_NOTE',
                                langCode: lang,
                            },

                            {
                                id: 7,
                                value: 7,
                                label: tawreedTranslationService.translate('lbl_payment_txn_txntype_7'),
                                type: 'TAWREED_COMMISSION',
                                langCode: lang,
                            },
                            {
                                id: 8,
                                value: 8,
                                label: tawreedTranslationService.translate('lbl_payment_txn_txntype_8'),
                                type: 'RETURN_TAWREED_COMMISSION',
                                langCode: lang,
                            },
                            {
                                id: 9,
                                value: 9,
                                label: tawreedTranslationService.translate('lbl_payment_txn_txntype_9'),
                                type: 'TOP_FEES',
                                langCode: lang,
                            },
                            {
                                id: 10,
                                value: 10,
                                label: tawreedTranslationService.translate('lbl_payment_txn_txntype_10'),
                                type: 'TOPUP_CASHBACK',
                                langCode: lang,
                            },
                            {
                                id: 11,
                                value: 11,
                                label: tawreedTranslationService.translate('lbl_payment_txn_txntype_11'),
                                type: 'TOPUP',
                                langCode: lang,
                            },
                            {
                                id: 12,
                                value: 12,
                                label: tawreedTranslationService.translate('lbl_payment_txn_txntype_12'),
                                type: 'TOPUPFEESEXPENSES',
                                langCode: lang,
                            },
                            {
                                id: 13,
                                value: 13,
                                label: tawreedTranslationService.translate('lbl_payment_txn_txntype_13'),
                                type: 'TAX',
                                langCode: lang,
                            },{
                                id: 31,
                                value: 31,
                                label: tawreedTranslationService.translate('lbl_payment_txn_txntype_13'),
                                type: 'MANUL_JOURNAL',
                                langCode: lang,
                            },
                        ],
                        bonusOperations: [
                            {
                                id: 1,
                                label: tawreedTranslationService.translate("lbl_eq_bonus_operation"),
                                langCode: lang,
                                type: "BONUS_OPERATIONS",
                                value: "EQUALS",

                            },
                            {
                                id: 2,
                                label: tawreedTranslationService.translate("lbl_gte_bonus_operation"),
                                langCode: lang,
                                type: "BONUS_OPERATIONS",
                                value: "GREATER_THAN",

                            },
                        ],
                        bonusTypes: [
                            {
                                id: 1,
                                label: tawreedTranslationService.translate("lbl_store_products_bonus_title"),
                                langCode: lang,
                                type: "BONUS_TYPE",
                                value: "EXTRA_ITEMS",

                            },
                            {
                                id: 2,
                                label: tawreedTranslationService.translate("lbl_store_products_discount_bonus_title"),
                                langCode: lang,
                                type: "BONUS_TYPE",
                                value: "DISCOUNT",

                            },
                        ],
                        quantityType: [
                            {
                                id: 0,
                                label: tawreedTranslationService.translate("lbl_stock_reset"),
                                langCode: lang,
                                type: "QUANTITY_TYPE",
                                value: IStockType.Rest,

                            },
                            {
                                id: 1,
                                label: tawreedTranslationService.translate("lbl_inbound"),
                                langCode: lang,
                                type: "QUANTITY_TYPE",
                                value: IStockType.Inbound,

                            },
                            {
                                id: 2,
                                label: tawreedTranslationService.translate("lbl_outbound"),
                                langCode: lang,
                                type: "QUANTITY_TYPE",
                                value: IStockType.Outbound,

                            },
                        ],
                        quantitySource: [
                            {
                                id: 0,
                                label: tawreedTranslationService.translate("lbl_movement_source_order"),
                                langCode: lang,
                                type: "QUANTITY_SOURCE",
                                value: IStockSource.Order,

                            },
                            {
                                id: 1,
                                label: tawreedTranslationService.translate("lbl_movement_source_manual"),
                                langCode: lang,
                                type: "QUANTITY_SOURCE",
                                value: IStockSource.Manual,

                            },
                            {
                                id: 2,
                                label: tawreedTranslationService.translate("lbl_movement_source_update"),
                                langCode: lang,
                                type: "QUANTITY_SOURCE",
                                value: IStockSource.Update,

                            },
                            {
                                id: 3,
                                label: tawreedTranslationService.translate("lbl_movement_source_external"),
                                langCode: lang,
                                type: "QUANTITY_SOURCE",
                                value: IStockSource.External,

                            },
                            {
                                id: 4,
                                label: tawreedTranslationService.translate("lbl_movement_source_import"),
                                langCode: lang,
                                type: "QUANTITY_SOURCE",
                                value: IStockSource.Import,

                            }
                        ],
                        financeAccountsTypes: res.data.financeAccountsTypes ?? [],
                        notificationTypes: res.data.notificationTypes.map((type : any) =>{
                            return {
                                id: type.id,
                                value: type.subType,
                                label: type.label,
                                type: type.type,
                                langCode: type.langCode
                            };
                        }) ?? [],
                        roles: res.data.roles?.map((e: any) => {
                            return {
                                id: RoleMapper.fromJson(e.roleCode),
                                value: RoleMapper.fromJson(e.roleCode),
                                label: e.roleName,
                                type: 'ROLES',
                                langCode: lang
                            };
                        }) ?? [],
                    },
                });
            })
            .catch(err => {
                dispatch({ type: 'CONSTANTS_FAILED', error: err });
            });
    }
}
