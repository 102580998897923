import { Button } from "primereact/button";
import React, { FC } from 'react';
import { tawreedTranslationService } from "../../../../common/translations";
import { GlobalContext } from "../../../../context";
import { AuthUtils } from "../../../auth/domain";
import { TawreedTable, TawreedTableProps } from "../../../../common";
import { Dialog } from "primereact/dialog";
import { CssUtils } from "../../../../helpers/css";
import { TawreedTableFormatters } from "../../../../common/components/table/formatters";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { PrimeIcons } from "primereact/api";
import { DashboardService } from "../../domain";
import { TawreedTableFilters } from "../../../../common/components/table/filters";

export interface PartnersDialogProps {
    hide: () => void;
    xlsxReportURI?: string;
    // pdfReportURI: string;
    visible: boolean;
    statusIds?:number[],
    startDate?: Date,
    endDate?: Date,
}

export const PartnersDialog: FC<PartnersDialogProps> = ({ visible, hide, xlsxReportURI, statusIds, startDate, endDate}) => {

    const service = React.useMemo(() => new DashboardService(), []);
    

    const PartnersDialogFooter = () => {
        return <div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={false} />

        </div>;
    }
    const PartnersDialogHeader = () => {
        return <div>
            <p>{tawreedTranslationService.translate("lbl_partners")}</p>
        </div>
    }
    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);

    const lang = AuthUtils.current().language;

    const tawreed: TawreedTableProps = {
        header: true,
        disableGlobalSearch: false,
        pagination: true,
        dataKey: 'partnerId',
        name: '',
        title: '',
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.searchPartners(req),
        },
        columns: [
            // {
            //     dataType: 'numeric',
            //     field: "partnerId",
            //     header: "lbl_sales_orders_header_order_id",
            //     sortable: true,
            //     filter: true,
            //     style: CssUtils.width('15%', CssUtils.color('var(--danger-color)')),
            // },
            {
                dataType: 'text',
                field: "partnerName",
                header: "lbl_partners_partner_name",
                sortable: true,
                sortField: 'partnerName',
                filter: true,
                filterField: 'partnerName',
                style: CssUtils.width('15%'),
            },
            {
                field: "startDate",
                header: "lbl_partners_start_date",
                sortable: true,
                filter: true,
                ...TawreedTableFilters.date.range("startDate", 'datetime'),
                style: CssUtils.width('15%'),
            },
            {
                dataType: 'text',
                field: "address.state",
                header: "lbl_partners_address_state_id",
                sortable: true,
                sortField: 'state',
                filter: true,
                filterField: 'state',
                style: CssUtils.width('15%', CssUtils.color('var(--success-color)')),
            },
            {
                dataType: 'text',
                field: "address.city",
                header: "lbl_partners_address_city_id",
                sortable: true,
                sortField: 'city',
                filter: true,
                filterField: 'city',

                style: CssUtils.width('10%'),
            }
        ],
        toolbar: {
            // actions: data && data?.length > 0 ? [

            //     new TawreedAction(TawreedActionName.ReportsExport, 'stateless', 'lbl_reports_export_as_excel', PrimeIcons.FILE_EXCEL, () => print(xlsxReportURI!)),
            // ] :
             actions: [],
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'partnerName': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
                'city': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        }
                    ],
                },
                'state': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        }
                    ],
                },
                statusId: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: statusIds && statusIds.length>0 ? statusIds  : undefined,
                            matchMode: 'in',
                        }
                    ]
                },
                startDate: {
                    operator: 'AND',
                    constraints: startDate && endDate ? [
                        {
                            value: startDate,
                            matchMode: 'dateAfter',
                        },
                        {
                            value: endDate,
                            matchMode: 'dateBefore',
                        }
                    ]: [
                        {
                            value: undefined,
                            matchMode: 'dateIs',
                        },
                    ]
                },
                businessType: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: 1,
                            matchMode: 'equals',
                        }
                    ]
                }
            }
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'partnerId',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },

    }

    return (

        <Dialog maximizable resizable style={{ minWidth: '80%' }}
            header={<PartnersDialogHeader />}
            visible={visible} footer={<PartnersDialogFooter />} onHide={hide} draggable={false}
            position="top" closable={true} closeOnEscape={true}>
            <TawreedTable  {...tawreed} />
        </Dialog>
    )
}
