import { JObject } from "../../../../common/data/models";
import { topOrdedPharmaciesDto } from "../../domain";

export const TopOrderedPharamciesChartMapper = {

    fromJson(json? : JObject): topOrdedPharmaciesDto {
        if (!json) {
            return {
            //    total: {
            //     label: 
            //    },
            pdfReportURI: "",
            xlsxReportURI: "",
            data: [],
            color: '#FFA726',
            };
        }
        return {
            color: '#FFA726',
            pdfReportURI: json.pdfReportURI,
            xlsxReportURI: json.xlsxReportURI,
            data: json.values.map((e:JObject) => {
                    return {
                        name: e.label,
                        label: e.label,
                        value: e.value2,
                    }
                }),
        };
    },
}