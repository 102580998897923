import { PartnersChartDto } from "../../../../dashboard/domain";
import { newJoinersChartDto, PartnerCountsDto, pharmaciesPerGovernateChartDto, topOrdedPharmaciesDto } from "../../../domain";

export type DashboardState = {

    partners: {
        /**
         * charts
         */
        counts: {
                    loading: boolean;
        
                    canShow: {
                        inactivePharmacie: boolean;
                    };
        
                    value: PartnerCountsDto | undefined;
                };
        charts: {

            newJoiners: {
                canShow: boolean;

                /**
                 * loading
                 */
                loading: boolean;

                /**
                 * totals
                 */
                totals: newJoinersChartDto | undefined;
            };

            pharmaciesPerGovernate: {
                canShow: boolean;

                /**
                 * loading
                 */
                loading: boolean;

                /**
                 * modes
                 */
                value: pharmaciesPerGovernateChartDto | undefined;
            };

            TopOrderedPharmacies: {
                canShow: boolean;

                /**
                 * loading
                 */
                loading: boolean;

                /**
                 * modes
                 */
                value: topOrdedPharmaciesDto | undefined;
            };
            
        }
    };
}
export type DashboardDetailState = {
    startDate: Date| undefined,
    endDate: Date| undefined,
    statusIds: number[] | undefined,
    visible: boolean,
}
export function getDashboardInitialState(): DashboardState {
    return {
        partners: {
            
            counts: {
                canShow: {
                    inactivePharmacie: false
                },
                loading: false,
                value: undefined,
            },
            charts: {
                newJoiners: {
                    canShow: false,
                    loading: false,
                    totals: undefined,
                },
                pharmaciesPerGovernate:{
                    canShow: false,
                    loading: false,   
                    value: undefined
                },
                TopOrderedPharmacies:{
                    canShow: false,
                    loading: false,   
                    value: undefined   
                }
            },
        },
    }
}
