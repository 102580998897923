import { PrimeIcons } from "primereact/api";
import { JObject } from "../../../../common/data/models";
import { PartnerCountsDto } from "../../domain";
import { Routes } from "../../../../router";

export const PartnerCountsMapper = {

    fromJson(json: number | undefined): PartnerCountsDto {
        return {
            InactivePharmacie: {
                name: 'Inactive pharmacies',
                label: 'lbl_partner_inactive_pharmacies',
                icon: PrimeIcons.BARS,
                // image: placedImg,
                value: json ?? 0,
                to: (_constants, user) => user.roleCode === 'CUSTOMER_ADMIN' ? Routes.SalesOrdersSearch : Routes.SalesOrdersPlacedSearch,
            },
        };
    },
}

