import { JObject } from "../../../../../common/data/models"
import { BonusMapper } from "../../../../catalog/store-products/data"
import { CustomerStoreCartItemDto } from "../../domain"
import { CustomerStoreCartItem } from "../models"

export const CustomerStoreCartItemMapper = {
    /**
     * Create cart model from json object
     * @param json
     * @returns {CustomerStoreCartItem}
     */
    fromJson: (json: JObject): CustomerStoreCartItem => {
        return {
            cartItemId: json['cartItemId'],
            cartId: json['cartId'],
            quantity: json['quantity'],
            productId: json['productId'],
            storeProductId: json['storeProductId'],
            retailPrice: json['retailPrice'],
            salePrice: json['salePrice'],
            productName: json['productName'],
            itemTotal: json['itemTotal'],
            createdAt: json['createdAt'],
            updatedAt: json['updatedAt'],
            expireDate: json['expireDate'],
            discountPercent: json['discountPercent'],
            availableQuantity: json['availableQuantity'],
            quantityOrdMin: json['quantityOrdMin'],
            itemGrossTotal: json['itemGrossTotal'],
            itemDiscount: json['itemDiscount'],
            itemTotalDiscount: json['itemTotalDiscount'],
            itemTotalTax: json['itemTotalTax'],
            sellingPrice: json['sellingPrice'],
            discountType: json['discountType'],
            discountValue: json['discountValue'],
            bonus: json['bonus'],
            storeProductTax: json['storeProductTax'],
            availableBonus: json['availableBonus'],
            itemTotalAfterBonusDiscount: json['itemTotalAfterBonusDiscount'],
            itemTotalTaxUsingOffers: json['itemTotalTaxUsingOffers'],
            bonusType: json['bonusType'],
        }
    },

    /**
     *
     */
    toJson: (cart: CustomerStoreCartItem): JObject => {
        return {
            ...cart,
        }
    },

    /**
     * Create cart model from json object
     * @param dto
     * @returns {CustomerStoreCartItem}
     */
    fromDto: (dto: CustomerStoreCartItemDto): CustomerStoreCartItem => {
        return {
            cartItemId: dto.cartItemId!,
            cartId: dto.cartId!,
            quantity: dto.quantity!,
            productId: dto.productId!,
            storeProductId: dto.storeProductId!,
            retailPrice: dto.retailPrice!,
            salePrice: dto.salePrice!,
            productName: dto.productName!,
            itemTotal: dto.itemTotal!,
            createdAt: dto.createdAt!,
            updatedAt: dto.updatedAt!,
            expireDate: dto.expireDate!,
            discountPercent: dto.discountPercent!,
            availableQuantity: dto.availableQuantity!,
            quantityOrdMin: dto.quantityOrdMin!,
            itemGrossTotal: dto.itemGrossTotal!,
            itemDiscount: dto.itemDiscount!,
            itemTotalDiscount: dto.itemTotalDiscount!,
            itemTotalTax: dto.itemTotalTax!,
            sellingPrice: dto.sellingPrice!,
            discountType: dto.discountType!,
            discountValue: dto.discountValue!,
            bonus: dto.bonus!,
            storeProductTax: dto.storeProductTax,
            bonusType: dto.bonusType,
            itemTotalTaxUsingOffers: dto.itemTotalTaxUsingOffers,
        }
    },

    /**
     *
     */
    toDto: (cart: CustomerStoreCartItem): CustomerStoreCartItemDto => {
        return {
            cartItemId: cart.cartItemId,
            cartId: cart.cartId,
            quantity: cart.quantity,
            productId: cart.productId,
            storeProductId: cart.storeProductId,
            retailPrice: cart.retailPrice,
            salePrice: cart.salePrice,
            productName: cart.productName,
            itemTotal: cart.itemTotal,
            createdAt: cart.createdAt,
            updatedAt: cart.updatedAt,
            expireDate: cart.expireDate,
            discountPercent: cart.discountPercent,
            availableQuantity: cart.availableQuantity,
            quantityOrdMin: cart.quantityOrdMin,
            itemGrossTotal: cart.itemGrossTotal,
            itemDiscount: cart.itemDiscount,
            itemTotalDiscount: cart.itemTotalDiscount,
            itemTotalTax: cart.itemTotalTax,
            sellingPrice: cart.sellingPrice,
            discountType: cart.discountType,
            discountValue: cart.discountValue,
            bonus: cart.bonus,
            storeProductTax: cart.storeProductTax,
            availableBonus: cart.availableBonus,
            itemTotalAfterBonusDiscount: cart.itemTotalAfterBonusDiscount ?? 0,
            bonusType: cart.bonusType,
            itemTotalTaxUsingOffers: cart.itemTotalTaxUsingOffers,
            appliedBonus: cart?.appliedBonus?.map((el: any) => BonusMapper.toDto(el)) ?? [],
        }
    },
}
