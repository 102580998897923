import { buildPaginatedRequest, PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { WindowUtils } from "../../../../helpers/window";
import { DashboardRepository } from "../../data";
import { FinancePartnerDto, PartnersChartDto, StoreInvoiceDto, TransactionChartAllDto } from "../dtos/dashboard";
import { TransactionDto } from "../dtos/Transaction";

export class DashboardService {
    private readonly repository = new DashboardRepository();

    public getFinanceReportForPartner(type: number): Promise<PartnersChartDto> {
        return this.repository.getFinanceReportForPartner(type);
    }
    public print(reportUrl: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                WindowUtils.print(reportUrl);
                resolve(true);
            } catch {
                reject();
            }
        });
    };
    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<FinancePartnerDto>> {
        return this.repository.search(request);
    }

    public getStoreInvoices(startDate: Date, endDate: Date): Promise<PartnersChartDto> {
        return this.repository.getStoreInvoices(startDate, endDate);
    }

    public searchStoreInvoices(startDate: Date, endDate: Date,
        request: PaginatedRequestParams): Promise<PaginatedResponse<StoreInvoiceDto>> {
        return this.repository.searchStoreInvoices(startDate, endDate, request);
    }

    public getTransactionValues(startDate: Date, endDate: Date, balanceId: number | undefined): Promise<TransactionChartAllDto> {
        return this.repository.getTransactionValues(startDate, endDate, balanceId);
    }

    public searchTrasaction(arg: PaginatedRequestParams | string): Promise<PaginatedResponse<TransactionDto>> {
        const request = typeof arg === 'string' ?
            buildPaginatedRequest({
                sort: { sortOrder: 1, sortField: 'transactionId', multiSortMeta: undefined },
                filters: {
                    orderId: {
                        operator: 'AND',
                        constraints: [
                            {
                                value: arg,
                                matchMode: "contains",
                            },
                        ],
                    },
                }
            })
            :
            buildPaginatedRequest({ ...arg, filters: { ...arg.filters } });
        return this.repository.searchTrasaction(request);
    }
}