import React from "react";
import { PaymentPartnerForm, PaymentPartnerTable } from "../components/partners";
import { PaymentTransactionForm, PaymentTransactionTable } from "../components/transactions";
import { PaymentOrderForm, PaymentorderTable } from "../components/orders";
import { BrokenOrderTable } from "../components/broken-order";
import { PaymentPartnerOnBoardForm, PaymentPartnerOnBoardTable } from "../components/onboard";

/**
 * This page shows all payment partners in Tawreed platform
 * @returns
 */
export const PaymentPartnerPage: React.FC = () => <PaymentPartnerTable />;


/**
 * This page shows details about one payment partner or create new
 * @returns
 */
export const PaymentPartneFormPage: React.FC = () => <PaymentPartnerForm />;

/**
 * @returns
 */
export const PaymentTransactionsPage: React.FC = () => <PaymentTransactionTable />;


/**
 *
 * @returns
 */
export const PaymentTransactionPage: React.FC = () => <PaymentTransactionForm />;


/**
 * @returns
 */
export const PaymentOrderPage: React.FC = () => <PaymentorderTable />;


export const PaymentOrderDetailsPage: React.FC = () => <PaymentOrderForm />;
/**
 * @returns
 */
export const BrokenOrderPage: React.FC = () => <BrokenOrderTable />;


//payment partner onboard
export const PaymentPartnerOnboardPage: React.FC = () => <PaymentPartnerOnBoardTable />;

export const PaymentPartnerOnboardDetailsPage: React.FC = () => <PaymentPartnerOnBoardForm />;