import React from "react"
import { Panel } from "primereact/panel"
import { useParams } from "react-router-dom"

import { TawreedCalendar, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitProps, TawreedInputNumber, TawreedInputText, TawreedMenuDropdown } from "../../../../../common/components"
import { tawreedTranslationService } from "../../../../../common/translations";
import { GlobalContext } from "../../../../../context"
import { PaymentPartnerOnboardService } from "../../../domain/services/onboard"
import { PaymentPartnerOnboard } from "../../../domain";
import { AuthUtils } from "../../../../auth/domain";

export const PaymentPartnerOnBoardForm: React.FC = () => {

    const { id } = useParams<{ id: string | undefined }>();
    const { auth: { user } } = React.useContext(GlobalContext);
    const service: PaymentPartnerOnboardService = React.useMemo(() => new PaymentPartnerOnboardService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = React.useState<PaymentPartnerOnboard>();

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode('Edit');
                setLoading(true);
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {
                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } else {
            if (mounted) {
                setMode('Create');
                setData(undefined);
            }
        }
        return () => {
            mounted = false;
        };
    }, [id, service]);


    const onSubmit: TawreedFormSubmitProps<PaymentPartnerOnboard> = { showButton: false, resetOnSubmit: false };

    return (
        <TawreedForm title="lbl_payment_partners_onboard"
            dataKey="paymentPartnerOnboardId" data={data}
            submit={onSubmit}
            loading={loading} mode={mode}
            useBack=".." className="grid form-grid">
            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">
                    <TawreedFormField name="paymentPartnerOnboardId" className="field col-12 md:col-6" title="lbl_payment_onboard_paymentPartnerOnboardId">
                        <TawreedInputText name="paymentPartnerOnboardId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="createdAt" className="field col-12 md:col-6" title="lbl_payment_onboard_createdat">
                        <TawreedCalendar name="createdAt" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>
                    {
                        AuthUtils.current().language === 'en' ?
                            <TawreedFormField name="paymentPartnerTitleEn" className="field col-12 md:col-6" title="lbl_payment_onboard_payment_partner">
                                <TawreedInputText name="paymentPartnerTitleEn" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                            </TawreedFormField>
                            :
                            <TawreedFormField name="paymentPartnerTitleAr" className="field col-12 md:col-6" title="lbl_payment_onboard_payment_partner">
                                <TawreedInputText name="paymentPartnerTitleAr" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                            </TawreedFormField>
                    }

                    <TawreedFormField name="customerName" className="field col-12 md:col-6" title="lbl_payment_onboard_partner_name">
                        <TawreedInputText name="customerName" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="nationalId" className="field col-12 md:col-6" title="lbl_payment_onboard_nationalId">
                        <TawreedInputText name="nationalId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="establishmentNationalId" className="field col-12 md:col-6" title="lbl_payment_onboard_establishmentNationalId">
                        <TawreedInputText name="establishmentNationalId" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="name" className="field col-12 md:col-6" title="lbl_payment_onboard_name">
                        <TawreedInputText name="name" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="limit" className="field col-12 md:col-6" title="lbl_payment_onboard_limit">
                        <TawreedInputText name="limit" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="reference" className="field col-12 md:col-6" title="lbl_payment_onboard_reference">
                        <TawreedInputText name="reference" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="mobile" className="field col-12 md:col-6" title="lbl_payment_onboard_mobile">
                        <TawreedInputText name="mobile" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="address" className="field col-12 md:col-6" title="lbl_payment_onboard_address">
                        <TawreedInputText name="address" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    {/* <TawreedFormField name="amount" className="field col-12 md:col-6" title="lbl_payment_txn_amount">
                        <TawreedInputNumber mode="decimal" name="amount" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>

                    <TawreedFormField name="status" className="field col-12 md:col-6" title="lbl_payment_txn_txnstatus">
                        <TawreedMenuDropdown name="status" menu="paymentransactionStatuses" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField> */}

                    <TawreedFormField name="status" className="field col-12 md:col-6" title="lbl_payment_onboard_status">
                        <TawreedInputText name="status" render="form" rules={{ required: 'msg_field_is_required' }} disabled />
                    </TawreedFormField>
                    {
                        data?.commercialRecordForm &&
                        <div className="field col-12 md:col-6">
                            <label>{tawreedTranslationService.translate("lbl_payment_onboard_commercialRecordForm")}</label>
                            <div className="text-center">
                                <img className={`flex-shrink-0 border-circle mt-1 ml-3 mb-1 notification-item-image`}
                                    style={{ maxHeight: '250px', maxWidth: '100%' }} src={`data:image/jpeg;base64,${data.commercialRecordForm}`} alt="" />
                            </div>
                        </div>
                    }
                    {
                        data?.idImage &&
                        <div className="field col-12 md:col-6">
                            <label>{tawreedTranslationService.translate("lbl_payment_onboard_idImage")}</label>
                            <div className="text-center">
                                <img style={{ maxHeight: '250px', maxWidth: '100%' }} className={`flex-shrink-0 border-circle mt-1 ml-3 mb-1 notification-item-image`}
                                    src={`data:image/jpeg;base64,${data.idImage}`} alt="" />
                            </div>
                        </div>
                    }
                    {
                        data?.sixMonthStatement &&
                        <div className="field col-12 md:col-6">
                            <label>{tawreedTranslationService.translate("lbl_payment_onboard_sixMonthStatement")}</label>
                            <div className="text-center">
                                <img style={{ maxHeight: '250px', maxWidth: '100%' }} className={`flex-shrink-0 border-circle mt-1 ml-3 mb-1 notification-item-image`}
                                    src={`data:image/jpeg;base64,${data.sixMonthStatement}`} alt="" />
                            </div>
                        </div>
                    }
                    {
                        data?.crifForm &&
                        <div className="field col-12 md:col-6">
                            <label>{tawreedTranslationService.translate("lbl_payment_onboard_crifForm")}</label>
                            <div className="text-center">
                                <img style={{ maxHeight: '250px', maxWidth: '100%' }} className={`flex-shrink-0 border-circle mt-1 ml-3 mb-1 notification-item-image`}
                                    src={`data:image/jpeg;base64,${data.crifForm}`} alt="" />
                            </div>
                        </div>
                    }
                </div>
            </Panel>
        </TawreedForm>
    )
}