import { Button } from "primereact/button";
import React, { FC } from 'react';
import { tawreedTranslationService } from "../../../../common/translations";
import { GlobalContext } from "../../../../context";
import { AuthUtils } from "../../../auth/domain";
import { TawreedTable, TawreedTableProps } from "../../../../common";
import { Dialog } from "primereact/dialog";
import { CssUtils } from "../../../../helpers/css";
import { TawreedTableFormatters } from "../../../../common/components/table/formatters";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { PrimeIcons } from "primereact/api";
import { DashboardService } from "../../domain";
import { TawreedTableFilters } from "../../../../common/components/table/filters";

export interface OrdersDialogProps {
    hide: () => void;
    xlsxReportURI?: string;
    // pdfReportURI: string;
    visible: boolean;
    storeIds?: number[],
    customerIds?: number[],
    statusIds?:number[],
    startDate?: Date,
    endDate?: Date,
    paymentModeIds?: number[],
    priceListIds?: number[]
}

export const OrdersDialog: FC<OrdersDialogProps> = ({ visible, hide, xlsxReportURI,storeIds, customerIds, statusIds, paymentModeIds, priceListIds, startDate, endDate}) => {

    const service = React.useMemo(() => new DashboardService(), []);
    const print = (report: string) => {
        return service.print(report)
            .then(() => {
            })
            .catch(err => console.error(err));
    };

    const FinancePartnerDialogFooter = () => {
        return <div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" disabled={false} />

        </div>;
    }
    const FinancePartnerDialogHeader = () => {
        return <div>
            <p>{tawreedTranslationService.translate("lbl_dashboard_partners_chart_title")}</p>
        </div>
    }
    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);

    const lang = AuthUtils.current().language;

    const tawreed: TawreedTableProps = {
        header: true,
        disableGlobalSearch: false,
        pagination: true,
        dataKey: 'orderId',
        name: '',
        title: '',
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(req),
        },
        columns: [
            {
                dataType: 'text',
                field: "statusId",
                header: "lbl_sales_orders_status",
                sortable: true,
                filter: true,
                ...TawreedTableFilters.dropdown.dropdown("statusId", constants?.orderStatuses ?? [], 'value', 'label', 'in'),
                style: CssUtils.width('10%'),
            },
            {
                dataType: 'numeric',
                field: "orderId",
                header: "lbl_sales_orders_header_order_id",
                sortable: true,
                filter: true,
                style: CssUtils.width('15%', CssUtils.color('var(--danger-color)')),
            },
            {
                field: "orderDate",
                header: "lbl_sales_orders_header_date",
                sortable: true,
                filter: true,
                ...TawreedTableFilters.date.range("orderDate", 'datetime'),
                style: CssUtils.width('15%'),
            },
            {
                dataType: 'text',
                field: "store.partnerName",
                header: "lbl_sales_orders_store",
                sortable: true,
                sortField: 'store',
                filter: true,
                filterField: 'store',
                style: CssUtils.width('15%'),
            },
            {
                dataType: 'text',
                field: "customer.partnerName",
                header: "lbl_sales_orders_customer",
                sortable: true,
                sortField: 'customer',
                filter: true,
                filterField: 'customer',
                style: CssUtils.width('15%', CssUtils.color('var(--success-color)')),
            },
            {
                dataType: 'text',
                field: "customer.address.state",
                header: "lbl_sales_orders_customer_state",
                sortable: true,
                sortField: 'customerStateName',
                filter: true,
                filterField: 'customerStateName',

                style: CssUtils.width('10%'),
            },
            {
                dataType: 'text',
                field: "priceListItem.name",
                header: "lbl_sales_orders_header_pricelistid",
                sortable: true,
                sortField: 'priceListName',
                filter: true,
                filterField: 'priceListName',
                style: CssUtils.width('10%'),
            },
            {
                dataType: 'text',
                field: "paymentModeName",
                header: "lbl_sales_orders_paymentmodeid",
                sortable: true,
                sortField: 'paymentModeName',
                filter: true,
                filterField: 'paymentModeName',
                style: CssUtils.width('10%'),
            },
            {
                field: "orderTotal",
                header: "lbl_sales_orders_header_order_total",
                sortable: true,
                filter: true,
                ...TawreedTableFormatters.decimal('orderTotal', user?.scale, 0, CssUtils.width('10%')),
                className: CssUtils.className.field.amount,
            }
        ],
        toolbar: {
            // actions: data && data?.length > 0 ? [

            //     new TawreedAction(TawreedActionName.ReportsExport, 'stateless', 'lbl_reports_export_as_excel', PrimeIcons.FILE_EXCEL, () => print(xlsxReportURI!)),
            // ] :
             actions: [],
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                storeId: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: storeIds && storeIds.length>0 ? storeIds  : undefined,
                            matchMode: 'in',
                        }
                    ]
                },
                customerId: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: customerIds && customerIds.length>0 ? customerIds  : undefined,
                            matchMode: 'in',
                        }
                    ]
                },
                statusId: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: statusIds && statusIds.length>0 ? statusIds  : undefined,
                            matchMode: 'in',
                        }
                    ]
                },
                paymentModeId:{
                    operator: 'AND',
                    constraints: [
                        {
                            value: paymentModeIds && paymentModeIds.length>0 ? paymentModeIds  : undefined,
                            matchMode: 'in',
                        }
                    ]
                },
                priceListId: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: priceListIds && priceListIds.length>0 ? priceListIds  : undefined,
                            matchMode: 'in',
                        }
                    ]
                },
                orderDate: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: startDate,
                            matchMode: 'dateAfter',
                        },
                        {
                            value: endDate,
                            matchMode: 'dateBefore',
                        }
                    ]
                }
            }
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'orderId',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },

    }

    return (

        <Dialog maximizable resizable style={{ minWidth: '80%' }}
            header={<FinancePartnerDialogHeader />}
            visible={visible} footer={<FinancePartnerDialogFooter />} onHide={hide} draggable={false}
            position="top" closable={true} closeOnEscape={true}>
            <TawreedTable  {...tawreed} />
        </Dialog>
    )
}
