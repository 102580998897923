import { JObject } from "../../../../common/data/models";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../common/pagination";
import { endpoints } from "../../../../constants/endpoints";
import { DateUtils } from "../../../../helpers/date";
import { tawreedHttpService } from "../../../../http";
import { FinancePartnerDto, PartnersChartDto, PartnersChartItemDto, StoreInvoiceDto, TransactionChartAllDto } from "../../domain/dtos/dashboard";
import { TransactionDto } from "../../domain/dtos/Transaction";
import { PartnersChartMapper } from "../mappers/partner-chart";
import { TransactionMapper } from "../mappers/transaction";
import { TransactionValuesChartMapper } from "../mappers/transaction-values-chart-mapper";

export class DashboardRepository {

    public getFinanceReportForPartner(type: number): Promise<PartnersChartDto> {
        return tawreedHttpService
            .post<number, Array<JObject>>(endpoints.DASHBOARD_PARTNER_REPORT, type, undefined, undefined, 'error')
            .then(res => PartnersChartMapper.fromJson(res.data,
                false, type !== 5
            ));
    }
    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<FinancePartnerDto>> {
        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = {
            ...buildFilterMetaToObject(request.keyword, request.filters),
        }
        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.DASHBOARD_PARTNER_REPORT_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => PartnersChartMapper.fromFinancePartnerJson(e)) ?? [] },
                };
            });
    }

    public getStoreInvoices(startDate: Date, endDate: Date): Promise<PartnersChartDto> {
        return tawreedHttpService
            .post<JObject, Array<JObject>>(endpoints.DASHBOARD_PARTNER_INVOICE,
                {
                    startDate: startDate,
                    endDate: endDate
                }, undefined, undefined, 'error')
            .then(res => PartnersChartMapper.fromPartnerInvoicesJson(res.data));
    }

    public searchStoreInvoices(startDate: Date, endDate: Date,
        request: PaginatedRequestParams): Promise<PaginatedResponse<StoreInvoiceDto>> {
        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = {
            startDate: startDate,
            endDate: endDate,
            keyword: request.keyword
        }
        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.DASHBOARD_PARTNER_INVOICE_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => PartnersChartMapper.fromStoreInvoiceDto(e)) ?? [] },
                };
            });
    }
    public getTransactionValues(startDate: Date, endDate: Date, balanceId: number|undefined): Promise<TransactionChartAllDto> {
        return tawreedHttpService
            .post<JObject, Array<JObject>>(endpoints.DASHBOARD_TRANSACTION_VALUES, {
                startDate: DateUtils.toISO(startDate, 'datetime', 'start'),
                endDate: DateUtils.toISO(endDate, 'datetime', 'end'),
                accountId:balanceId
            }, undefined, undefined, 'error')
            .then(res => {
                return TransactionValuesChartMapper.fromJson(res.data!);
            });
    }
    public searchTrasaction(arg: PaginatedRequestParams): Promise<PaginatedResponse<TransactionDto>> {
            const params = {
                sort: arg.sort ? buildSortParamsToString(arg.sort) : undefined,
                page: arg.page,
                size: arg.size
            };
            const data = { ...buildFilterMetaToObject(arg.keyword, arg.filters) };
    
            return tawreedHttpService
                .post<JObject, Result<JObject>>( endpoints.DASHBOARD_TRANSACTION_SEARCH, data, params, undefined, 'error')
                .then(res => {
                    return {
                        ...res,
                        data: {
                            ...res.data!,
                            content: res.data!.content.map(e => TransactionMapper.toDto(e)) ?? [],
                        }
                    }
                });
        }
}