import { tawreedHttpService } from "../../../../http";
import { endpoints } from "../../../../constants/endpoints";
import { JObject } from "../../../../common/data/models";
import { DateUtils } from "../../../../helpers/date";
import { newJoinersChartDto, PartnerCountsDto, pharmaciesPerGovernateChartDto, topOrdedPharmaciesDto } from "../../domain";
import { PartnerCountsMapper, PartnerNewJoinerChartMapper, TopOrderedPharamciesChartMapper } from "../mappers";
import { PharmaciesPerGovernateChartMapper } from "../mappers/pharmacies-per-governate-chart";
import { PartnerMapper, PartnerType } from "../../../partners/data";
import { OrderDto } from "../../../sales/orders/domain";
import { OrderMapper } from "../../../sales/orders/data/mappers";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../common/pagination";
import { PartnerDto } from "../../../partners/domain";

export class PartnerDashboardRepository {

    public getNewJoinersChart(startDate: Date, endDate: Date, statusIds: number[] | undefined): Promise<newJoinersChartDto> {
        return tawreedHttpService
            .post<JObject, Array<JObject>>(endpoints.PARTNER_DASHBOARD_NEW_JOINERS_CHART, {
                startDate: DateUtils.toISO(startDate, 'datetime', 'start'),
                endDate: DateUtils.toISO(endDate, 'datetime', 'end'),
                statusIds: statusIds
            }, undefined, undefined, 'error')
            .then(res => {
                return PartnerNewJoinerChartMapper.fromJson(res.data);
            });
    }
    public getPharmaciesPerGovernateChartsCounts(): Promise<pharmaciesPerGovernateChartDto> {
        return tawreedHttpService
            .post<JObject, Array<JObject>>(endpoints.PARTNER_DASHBOARD_PHARMACIES_PER_GOVERNATE_CHART, {}, undefined, undefined, 'error')
            .then(res => PharmaciesPerGovernateChartMapper.fromJson(res.data));
    }
    public getTopOrderedPharmacies(startDate: Date, endDate: Date): Promise<topOrdedPharmaciesDto> {
        return tawreedHttpService
            .post<JObject, Array<JObject>>(endpoints.PARTNER_DASHBOARD_PHARMACIES_TOP_OREDER_CHART, {
                startDate: DateUtils.toISO(startDate, 'datetime', 'start'),
                endDate: DateUtils.toISO(endDate, 'datetime', 'end')
            }, undefined, undefined, 'error')
            .then(res => TopOrderedPharamciesChartMapper.fromJson(res.data));
    }
    public getPartnerCount(startDate: Date, endDate: Date): Promise<PartnerCountsDto> {
        return tawreedHttpService
            .post<JObject, number>(endpoints.PARTNER_DASHBOARD_INACTIVE_PHARMACIES_CHART, {
                startDate: DateUtils.toISO(startDate, 'datetime', 'start'),
                endDate: DateUtils.toISO(endDate, 'datetime', 'end')
            }, undefined, undefined, 'error')
            .then(res => PartnerCountsMapper.fromJson(res.data));
    }
    public searchPartners(arg: PaginatedRequestParams): Promise<PaginatedResponse<PartnerDto>> {
        const params = {
            sort: arg.sort ? buildSortParamsToString(arg.sort) : undefined,
            page: arg.page,
            size: arg.size
        };
        const data = { ...buildFilterMetaToObject(arg.keyword, arg.filters) };

        return tawreedHttpService
            .post<JObject, Result<JObject>>(endpoints.PARTNERS_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => PartnerMapper.toDto(PartnerMapper.fromJson(e))) ?? [] },
                };
            });
    }
     public searchOrders(arg: PaginatedRequestParams): Promise<PaginatedResponse<OrderDto>> {
            const endpoint: string = endpoints.ORDERS_SEARCH;
            const params = {
                sort: arg.sort ? buildSortParamsToString(arg.sort) : undefined,
                page: arg.page,
                size: arg.size
            };
            const data = { ...buildFilterMetaToObject(arg.keyword, arg.filters) };
    
            return tawreedHttpService
                .post<JObject, Result<JObject>>(endpoint, data, params, undefined, 'error')
                .then(res => {
                    return {
                        ...res,
                        data: {
                            ...res.data!,
                            content: res.data!.content.map(e => this.fromJsonToOrderDto2(e)) ?? [],
                        }
                    }
                });
        }
        private fromJsonToOrderDto2(json: JObject | undefined): OrderDto {
    
            const customer = PartnerMapper.toDto(PartnerMapper.fromJson({
                partnerId: json?.customerId,
                partnerName: json?.customer,
                partnerType: PartnerType.Customer,
                state: json?.customerStateName,
                contact: {
                    contactId: json?.contact?.contactId,
                    contactName: json?.contact?.contactName,
                    email: json?.contact?.email,
                    mobileNo: json?.contact?.mobileNo,
                },
                address: {
                    addressId: json?.address?.addressId,
                    state: json?.address?.state,
                    stateId: json?.address?.stateId,
                    zoneId: json?.address?.zoneId,
                    latitude: json?.address?.latitude,
                    longitude: json?.address?.longitude,
                    street: json?.address?.street,
                    fullAddress: json?.address?.fullAddress,
                },
            }));
    
            const store = PartnerMapper.toDto(PartnerMapper.fromJson({
                partnerId: json?.storeId,
                partnerName: json?.store,
                partnerType: PartnerType.Store,
                contact: {
                    contactId: json?.storeContact?.contactId,
                    contactName: json?.storeContact?.contactName,
                    email: json?.storeContact?.email,
                    mobileNo: json?.storeContact?.mobileNo,
                },
                address: {
                    addressId: json?.storeAddress?.addressId,
                    state: json?.storeAddress?.state,
                    stateId: json?.storeAddress?.stateId,
                    zoneId: json?.storeAddress?.zoneId,
                    latitude: json?.storeAddress?.latitude,
                    longitude: json?.storeAddress?.longitude,
                    street: json?.storeAddress?.street,
                    fullAddress: json?.storeAddress?.fullAddress,
                },
            }));
            return OrderMapper.toDto(OrderMapper.fromJson(json!, customer, store));
        }

}
