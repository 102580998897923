import React, { useEffect } from "react";
import { GlobalContext } from "../../../../context";
import { DashboardFinancePartnersChart } from "./dashboard-finance-partners-chart";
import { DashboardService } from "../../domain";
import { DashboardItemValue, PartnersChartDto } from "../../domain/dtos/dashboard";
import { tawreedTranslationService } from "../../../../common/translations";
import { FinancePartnerDialog } from "./finance-partner-dialog";
import { DateUtils } from "../../../../helpers/date";
import { DashboardFinanceTransactionChart } from "./dashboar-finance-transaction-chart";
import { DashboardDetailsState, DashboardState, getDashboardInitialState } from "./dashboard.state";
import { FinanceTransactionDialog } from "./finance-transaction-dialog";


export type DashboardLiabilitiesState = {
    partners: PartnersChartDto | undefined;
    loading: boolean
}
export type DashboardDateState = {
    startDate: Date;
    endDate: Date;
}
export const FinanceDashboardComponent: React.FC = (_props) => {
    const service = React.useMemo(() => new DashboardService(), []);
    const [type, setType] = React.useState<number | undefined>(undefined);
    const [xlsxReportURI, setXlsxReportURI] = React.useState<string | undefined>(undefined);
    const [pdfReportURI, setPdfReportURI] = React.useState<string | undefined>(undefined);
    
    const { constants: { constants }, auth: { user } } = React.useContext(GlobalContext);
    
    const onPrint = (report: string) => {
        return service.print(report)
            .then(() => {
            })
            .catch(err => console.error(err));
    };

    const onDetials = (type: number, xlsxReportURI: string, pdfReportUri: string) => {
        setType(type);
        setXlsxReportURI(xlsxReportURI);
        setPdfReportURI(pdfReportUri);
    };

    
    
    const [storesLiabilities, setStoresLiabilities] = React.useState<DashboardLiabilitiesState>({
        partners: undefined,
        loading: true
    });
    const [storesDues, setStoresDues] = React.useState<DashboardLiabilitiesState>({
        partners: undefined,
        loading: true
    });
    const [topupBalances, setTopupBalances] = React.useState<DashboardLiabilitiesState>({
        partners: undefined,
        loading: true
    });
    const [customerWallet, setCustomerWallet] = React.useState<DashboardLiabilitiesState>({
        partners: undefined,
        loading: true
    });
    const [bankBalances, setBankBalances] = React.useState<DashboardLiabilitiesState>({
        partners: undefined,
        loading: true
    });

    const [storeInvoices, setStoreInvoices] = React.useState<DashboardLiabilitiesState>({
        partners: undefined,
        loading: true
    });

    const [storeinvoicesDate, setStoreInvoicesDate] = React.useState<DashboardDateState>({
        startDate: DateUtils.now(),
        endDate: DateUtils.now(),
    });

    const [dashboardState, setDashboardState] = React.useState<DashboardState>(getDashboardInitialState());

    const [detailState, setDetailState] = React.useState<DashboardDetailsState>({
        startDate: undefined, endDate: undefined, balanceId: undefined, visible: false, name: undefined
    });
    const onHide = () =>{
        setDetailState({
            startDate: undefined, endDate: undefined, balanceId: undefined, visible: false, name: undefined
        })
    };

    useEffect(()=>{
        service.getFinanceReportForPartner(1)
        .then(res => 
        {
            setStoresLiabilities({partners: res, loading: false})
        })
        .catch(() => {});
        service.getFinanceReportForPartner(2)
        .then(res => 
        {
            setStoresDues({partners: res, loading: false})
        })
        .catch(() => {});
        service.getFinanceReportForPartner(3)
        .then(res => 
        {
            setTopupBalances({partners: res, loading: false})
        })
        .catch(() => {});
        service.getFinanceReportForPartner(4)
        .then(res => 
        {
            setCustomerWallet({partners: res, loading: false})
        })
        .catch(() => {});
        service.getFinanceReportForPartner(5)
        .then(res => 
        {
            setBankBalances({partners: res, loading: false})
        })
        .catch(() => {});
        service.getStoreInvoices(storeinvoicesDate.startDate, storeinvoicesDate.endDate)
        .then(res => 
        {
            setStoreInvoices({ partners: res, loading: false})
        })
        .catch(() => {});
    },[service]);

    const ontransactionDetails = React.useCallback((startDate: Date, endDate: Date, name: string): void => {
        var balanceId : number|undefined = undefined;
        switch (name) {
            case "revenue":
                balanceId = 10;
                break;
            case "TOPUP_cost":
                balanceId = 25;
                break;
            case "CASHBack":
                balanceId = 22;
                break;
        }
                setDetailState({
                    startDate: startDate, endDate: endDate, 
                    balanceId: balanceId, visible: true, name:name
                })
            },[service]);
     const onSearchRevenue = React.useCallback((startDate: Date, endDate: Date): void => {
            setDashboardState(previous => {
                return {
                    ...previous,
                    financial: {
                        ...previous.financial,
                        charts: {
                            ...previous.financial.charts,
                            revenue: {
                                ...previous.financial.charts.revenue,
                                loading: true,
                                value: undefined
                            },
                        }
                    },
                }
            });
            service.getTransactionValues(startDate, endDate, 10)
                .then(res => {
                    setDashboardState(previous => {
                        return {
                            ...previous,
                            financial: {
                                ...previous.financial,
                                charts: {
                                    ...previous.financial.charts,
                                    revenue: {
                                        ...previous.financial.charts.revenue,
                                        loading: false,
                                        value: res,
                                    },
                                }
                            },
                        }
                    });
                })
                .catch(() => {
                    setDashboardState(previous => {
                        return {
                            ...previous,
                            financial: {
                                ...previous.financial,
                                charts: {
                                    ...previous.financial.charts,
                                    revenue: {
                                        ...previous.financial.charts.revenue,
                                        loading: false
                                    },
                                }
                            },
                        }
                    });
                });
        }, [service]);
        
        
        const onSearchTopupCost = React.useCallback((startDate: Date, endDate: Date): void => {
            setDashboardState(previous => {
                return {
                    ...previous,
                    financial: {
                        ...previous.financial,
                        charts: {
                            ...previous.financial.charts,
                            topupCost: {
                                ...previous.financial.charts.topupCost,
                                loading: true,
                                value: undefined
                            },
                        }
                    },
                }
            });
            service.getTransactionValues(startDate, endDate, 25)
                .then(res => {
                    setDashboardState(previous => {
                        return {
                            ...previous,
                            financial: {
                                ...previous.financial,
                                charts: {
                                    ...previous.financial.charts,
                                    topupCost: {
                                        ...previous.financial.charts.topupCost,
                                        loading: false,
                                        value: res,
                                    },
                                }
                            },
                        }
                    });
                })
                .catch(() => {
                    setDashboardState(previous => {
                        return {
                            ...previous,
                            financial: {
                                ...previous.financial,
                                charts: {
                                    ...previous.financial.charts,
                                    topupCost: {
                                        ...previous.financial.charts.topupCost,
                                        loading: false
                                    },
                                }
                            },
                        }
                    });
                });
        }, [service]);
        
        const onSearchCahsBack = React.useCallback((startDate: Date, endDate: Date): void => {
            setDashboardState(previous => {
                return {
                    ...previous,
                    financial: {
                        ...previous.financial,
                        charts: {
                            ...previous.financial.charts,
                            cashBack: {
                                ...previous.financial.charts.cashBack,
                                loading: true,
                                value: undefined
                            },
                        }
                    },
                }
            });
            service.getTransactionValues(startDate, endDate, 22)
                .then(res => {
                    setDashboardState(previous => {
                        return {
                            ...previous,
                            financial: {
                                ...previous.financial,
                                charts: {
                                    ...previous.financial.charts,
                                    cashBack: {
                                        ...previous.financial.charts.cashBack,
                                        loading: false,
                                        value: res,
                                    },
                                }
                            },
                        }
                    });
                })
                .catch(() => {
                    setDashboardState(previous => {
                        return {
                            ...previous,
                            financial: {
                                ...previous.financial,
                                charts: {
                                    ...previous.financial.charts,
                                    cashBack: {
                                        ...previous.financial.charts.cashBack,
                                        loading: false
                                    },
                                }
                            },
                        }
                    });
                });
        }, [service]);

        const onSearchTopup = React.useCallback((startDate: Date, endDate: Date): void => {
            setDashboardState(previous => {
                return {
                    ...previous,
                    financial: {
                        ...previous.financial,
                        charts: {
                            ...previous.financial.charts,
                            topup: {
                                ...previous.financial.charts.topup,
                                loading: true,
                                value: undefined
                            },
                        }
                    },
                }
            });
            service.getTransactionValues(startDate, endDate, undefined)
                .then(res => {
                    setDashboardState(previous => {
                        return {
                            ...previous,
                            financial: {
                                ...previous.financial,
                                charts: {
                                    ...previous.financial.charts,
                                    topup: {
                                        ...previous.financial.charts.topup,
                                        loading: false,
                                        value: res,
                                    },
                                }
                            },
                        }
                    });
                })
                .catch(() => {
                    setDashboardState(previous => {
                        return {
                            ...previous,
                            financial: {
                                ...previous.financial,
                                charts: {
                                    ...previous.financial.charts,
                                    topup: {
                                        ...previous.financial.charts.topup,
                                        loading: false
                                    },
                                }
                            },
                        }
                    });
                });
        }, [service]);
    
    
    const valueCalculator = (e: DashboardItemValue): string => {
        if (e === undefined || e == null) {
            return '';
        } else if (typeof e === 'number') {
            return e.toString();
        } else if (typeof e === 'string') {
            return e;
        } else {
            if (constants && user) {
                return ''//e(constants, user).toString();
            }
            return '';
        }
    }
    const onDateChange = (sender: 'startDate' | 'endDate', value: Date) => {
        setStoreInvoicesDate({ ...storeinvoicesDate, [sender]: value});
        
    }

    useEffect(()=>{
        setStoreInvoices({ ...storeInvoices, loading: true})
        service.getStoreInvoices(storeinvoicesDate.startDate!, storeinvoicesDate.endDate!)
        .then(res => 
        {
            setStoreInvoices({partners: res, loading: false})
        })
        .catch(() => {});
    },
    [storeinvoicesDate.startDate, storeinvoicesDate.endDate]);

    return (
        <React.Fragment>
             <div className="grid">
            <div className="col-12">
                <h3>{tawreedTranslationService.translate("lbl_dashboard")}</h3>
            </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinancePartnersChart
                            showDetails={onDetials}
                            type={1}
                            className=""
                            displayPartner={true}
                            showCalendar={false}
                            title="lbl_stores_liabilities"
                            showTotal={true}
                            //type={}
                            onChange={()=>{}}
                            loading={storesLiabilities?.loading}
                            dto={storesLiabilities?.partners}/>
                    </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinancePartnersChart
                            showDetails={onDetials}
                            type={2}
                            title="lbl_stores_dues"
                            showTotal={true}
                            showCalendar={false}
                            onChange={()=>{}}
                            className=""
                            displayPartner={true}
                            loading={storesDues?.loading}
                            dto={storesDues?.partners}/>
                    </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinancePartnersChart
                            title="lbl_topup_balances"
                            showDetails={onDetials}
                            type={3}
                            showTotal={false}
                            showCalendar={false}
                            onChange={()=>{}}
                            className=""
                            displayPartner={true}
                            loading={topupBalances?.loading}
                            dto={topupBalances?.partners}/>
                    </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinancePartnersChart
                            showTotal={true}
                            showCalendar={false}
                            onChange={()=>{}}
                            showDetails={onDetials}
                            type={4}
                            title="lbl_customer_wallet_balances"
                            className=""
                            displayPartner={true}
                            loading={customerWallet?.loading}
                            dto={customerWallet?.partners}/>
                    </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinancePartnersChart
                            showDetails={onDetials}
                            type={5}
                            onChange={()=>{}}
                            title="lbl_bank_balances"
                            showTotal={false}
                            displayPartner={false}
                            className=""
                            showCalendar={false}
                            loading={bankBalances?.loading}
                            dto={bankBalances?.partners}/>
                    </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinancePartnersChart
                            showDetails={onDetials}
                            type={6}
                            title="lbl_store_invoices"
                            showTotal={false}
                            displayPartner={false}
                            showCalendar={true}
                            onChange= {onDateChange}
                            startDate={storeinvoicesDate.startDate}
                            endDate={storeinvoicesDate.endDate}
                            className=""
                            loading={storeInvoices?.loading}
                            dto={storeInvoices?.partners}/>
                    </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinanceTransactionChart  
                            name={"revenue"} className=""
                            dto={dashboardState.financial.charts.revenue.value}
                            calculator={valueCalculator}
                            loading={dashboardState.financial.charts.revenue.loading}
                            onSearch={onSearchRevenue}
                            onDetails={ontransactionDetails}
                            />
                    </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinanceTransactionChart 
                            name={"TOPUP_cost"}
                            className=""
                            dto={dashboardState.financial.charts.topupCost.value}
                            calculator={valueCalculator}
                            loading={dashboardState.financial.charts.topupCost.loading}
                            onSearch={onSearchTopupCost}
                            onDetails={ontransactionDetails}
                            />
                    </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinanceTransactionChart 
                            name={"CASHBack"}
                            className=""
                            dto={dashboardState.financial.charts.cashBack.value}
                            calculator={valueCalculator}
                            loading={dashboardState.financial.charts.cashBack.loading}
                            onSearch={onSearchCahsBack}
                            onDetails={ontransactionDetails}
                            />
                    </div>
                    <div className="col-12 md:col-12">
                        <DashboardFinanceTransactionChart  
                            name={"topup"}
                            className=""
                            dto={dashboardState.financial.charts.topup.value}
                            calculator={valueCalculator}
                            loading={dashboardState.financial.charts.topup.loading}
                            onSearch={onSearchTopup}
                            onDetails={ontransactionDetails}
                            />
                    </div>
                    </div>
                <FinancePartnerDialog
                hide={()=>{setType(undefined); 
                    setPdfReportURI(undefined);
                    setXlsxReportURI(undefined);
                }}
                type={type}
                print={onPrint}
                pdfReportURI = {pdfReportURI?? ""}
                xlsxReportURI={xlsxReportURI?? ""}
                startDate={storeinvoicesDate.startDate}
                endDate={storeinvoicesDate.endDate}
                ></FinancePartnerDialog>
                <FinanceTransactionDialog 
                hide={()=> onHide()}
                visible={detailState.visible}
                startDate={detailState.startDate}
                endDate={detailState.endDate}
                balanceId={detailState.balanceId}
                name={detailState.name}
                />
                </React.Fragment>

                
    );
}