import React from "react";
import { tawreedTranslationService } from "../../../../common/translations";
import { Ripple } from "primereact/ripple";
import { DashboardCardItemDto, DashboardItemRoute, DashboardItemValue } from "../../domain";
import { Skeleton } from "primereact/skeleton";
import classNames from "classnames";
import { useHistory } from "react-router";
import { DateUtils } from "../../../../helpers/date";
import { PrimeIcons } from "primereact/api";
import { TawreedCalendar } from "../../../../common";


declare type DashboardInactivePharmaciesProps = {
    /**
     * className
     */
    className?: string;

    /**
     * loading
     */
    loading: boolean;

    /**
     *
     */
    item: DashboardCardItemDto | undefined;

    
    /**
     *
     * @param startDate
     * @param endDate
     */
    onSearch(startDate: Date, endDate: Date): void;

    valueCalculator: (value: DashboardItemValue) => string;
    routeCalculator: (value: DashboardItemRoute) => string;
}

declare type DashboardInactivePharmaciesState = {

    value: DashboardCardItemDto | undefined,
    startDate: Date;
    endDate: Date;
}

export const DashboardInactivePharmaciesCard: React.FC<DashboardInactivePharmaciesProps> = (props) => {

     const [state, setState] = React.useState<DashboardInactivePharmaciesState>({
            startDate: DateUtils.now(-7),
            endDate: DateUtils.now(),
            value: undefined
        });
    const getImageOrIcon = (item: DashboardCardItemDto) => {
        if (item.image) {
            return <img src={item.image} alt='' />
        }
        else {
            return <i className={"text-blue-500 text-xl " + item.icon}></i>
        }

    }
    React.useEffect(() => {
    
            let mounted = true;
    
            if (mounted && !props.loading) {
                props.onSearch(state.startDate, state.endDate);
            }
    
            return () => {
                mounted = false;
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [state.startDate, state.endDate]);
    

        const onChange = (sender: 'startDate' | 'endDate', value: Date) => {
            setState({...state, [sender]: value});
        }
    return (
        <div className={classNames('card mb-0 p-ripple', { 'cursor-pointer': props.item && props.item.to })}>
            {
                props.item && props.item.to && <Ripple />
            }
            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12 sm:col-6">
                                        {/* <SelectButton className="p-button-sm" optionLabel="label" optionValue="value" value={state.selected} options={state.available} onChange={(e) => onSelectedDataSetChange(e.value)}/> */}
                                    </div>
                                    <div className="col-12 sm:col-6">
                                        <div className="flex justify-content-start sm:justify-content-end">
                                            <i className={"my-auto mr-2 " + PrimeIcons.CALENDAR} style={{fontSize: '1.5rem'}}/>
                                            <div style={{width: '10rem'}}>
                                                <TawreedCalendar render="standalone" disabled={props.loading} value={state.startDate} onChange={e => onChange('startDate', e.value as Date)}/>
                                            </div>
                                            <div style={{width: '1rem', height: '1rem'}}/>
                                            <div style={{width: '10rem'}}>
                                                <TawreedCalendar render="standalone" disabled={props.loading} value={state.endDate} onChange={e => onChange('endDate', e.value as Date)}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            <div className="flex justify-content-between mb-1">
                <div className="w-full">
                    <span className="block text-500 font-medium mb-3">
                        {
                            (props.loading || !props.item) ? <Skeleton /> : tawreedTranslationService.translate(props.item.label)
                        }
                    </span>
                    <div className="text-900 font-medium text-xl">
                        {
                            (props.loading || !props.item) ? <Skeleton /> : props.valueCalculator(props.item.value)
                        }
                    </div>
                </div>
                <div className={`flex align-items-center justify-content-center border-round ${props.item?.image ? '' : 'bg-blue-100'}`} style={{ width: '2.5rem', height: '2.25rem' }}>
                    {
                        (props.loading || !props.item) ? <Skeleton shape="rectangle" height="100%" width="100%" /> : getImageOrIcon(props.item)
                    }
                </div>
            </div>
            <span className="text-green-500 font-medium">
                {
                    (props.loading || !props.item) ? <Skeleton /> : (props.item.subValue && props.valueCalculator(props.item.subValue))
                }
                &#8203;
            </span>
        </div>
    )
}
