import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState } from 'react';
import OtpInput from 'react-otp-input';
import { tawreedTranslationService } from '../../../../../common/translations';

export interface OtpDialogProps {
    visible: boolean;

    hide: () => void;

    accept: (otp:string) => void;
}

export const OtpDialog: FC<OtpDialogProps> = ({ visible, hide, accept }) => {


    const [otpValue, setOtpValue] = useState<
         string|undefined
        >(undefined);
    

    const BonusDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" />
            <Button onClick={() => accept(otpValue!)} label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" disabled={!otpValue || otpValue.length !== 4} />
        </div>);
    }

    return (

        <Dialog style={{ maxWidth: '512px', minWidth: '55%' }} header={<span style={{ fontSize: '13px', fontWeight: 'bold' }}>{tawreedTranslationService.translate('lbl_otp_message')}</span>}
            visible={visible} footer={<BonusDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>


            <React.Fragment>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <OtpInput
                        inputStyle={{ width: '100%', height: '50px' }}
                        numInputs={4}
                        isInputNum
                        separator={<span>-</span>}
                        value={otpValue}
                        onChange={(e: any) => {
                            setOtpValue(e);
                        }}
                    />
                </div>


            </React.Fragment>

        </Dialog >
    )
}
