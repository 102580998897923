import { TransactionChartAllDto } from "../../domain/dtos/dashboard";

export type DashboardState = {
    /**
     * orders
     */
    financial: {
        /**
         * charts
         */
        charts: {

            revenue: {
                canShow: boolean;

                /**
                 * loading
                 */
                loading: boolean;

                
                value: TransactionChartAllDto | undefined;
            };

            topupCost: {
                canShow: boolean;

                /**
                 * loading
                 */
                loading: boolean;

                
                value: TransactionChartAllDto | undefined;
            };
            cashBack: {
                canShow: boolean;

                /**
                 * loading
                 */
                loading: boolean;

                
                value: TransactionChartAllDto | undefined;
            },
            topup:{
                canShow: boolean;

                /**
                 * loading
                 */
                loading: boolean;

                
                value: TransactionChartAllDto | undefined;
            }
        }
    };
}

export type DashboardDetailsState = {
    startDate?: Date;
    endDate?: Date;
    balanceId?: number;
    visible: boolean;
    name?:string;
}

export function getDashboardInitialState(): DashboardState {
    return {
        financial: {
            charts: {
                revenue: {
                    canShow: false,
                    loading: false,
                    value: undefined,
                },
                topupCost:{
                    canShow: false,
                    loading: false,
                    value: undefined,
                },
                cashBack:{
                    canShow: false,
                    loading: false,
                    value: undefined,
                },
                topup: {
                    canShow: false,
                    loading: false,
                    value: undefined,
                },
            },
        },
    }
}
