import React, { useState } from "react";
import { TawreedTable, TawreedTableColumnProps, TawreedTableProps } from "../../../../../common/components/table";
import { OrderItemDto, orderService as service } from "../../domain";
import { TawreedTableEditor } from "../../../../../common/components/table/editors";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { tawreedTranslationService } from "../../../../../common/translations";
import { CssUtils } from "../../../../../helpers/css";
import { TawreedActionName } from "../../../../../common/actions";
import { AuthUtils } from "../../../../auth/domain";
import { GlobalContext } from "../../../../../context";
import { IBonusOperations, IBonusType } from "../../../../constants";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { OrderItemChangeBonusDialog } from "./order-item-change-bonus-dialog";
import { BonusDto } from "../../../../catalog/store-products/domain";

export type OrderFormItemsProps = {
    // controls
    className: string;
    // data
    showEdit?: boolean;
    // items?: Array<OrderItemDto>;
    showNote: boolean;
    orderId?: number;
    reload?: boolean;
    // actions
    onSave: (item: OrderItemDto) => Promise<boolean>;

    returned: boolean | undefined;
};
export const OrderFormItems: React.FC<OrderFormItemsProps> =
    (props) => {

        const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);

        const [updateItemDialog, setUpdateItemDialog] = useState<boolean>(false);

        const [selectedItem, setSelectedItem] = useState<OrderItemDto>();

        const getBox = (discount: string) => {
            if (discount === 'fixed')
                return tawreedTranslationService.translate("lbl_unit");
            if (discount === 'percentage')
                return "%"

            return "%+"
        }
        const lang = AuthUtils.current().language;
        let columns: TawreedTableColumnProps[] = props.returned ?
            [
                {
                    dataType: 'text',
                    field: "productName",
                    sortable: true,
                    header: "lbl_sales_orders_o_info_items_product_name",
                    style: CssUtils.width('20%'),
                    bodyStyle: { 'unicode-bidi': 'plaintext' }
                },
                {
                    dataType: 'numeric',
                    field: "quantity",
                    sortable: true,
                    header: "lbl_sales_orders_o_info_items_quantity",
                    body: props.showEdit ? TawreedTableEditor.numeric('int', 'quantityOrdered', 0, !AuthUtils.current().authorized(TawreedActionName.OrdersEditQuantity)) : undefined,
                    style: CssUtils.width('20%'),
                },
                {
                    dataType: 'numeric',
                    field: "retailPrice",
                    sortable: true,
                    header: "lbl_sales_orders_o_info_items_retail_price",
                    style: CssUtils.width('20%'),
                },
                {
                    dataType: 'numeric',
                    field: "discountPercent",
                    sortable: true,
                    header: "lbl_sales_orders_o_info_items_discount_percent",
                    style: CssUtils.width('20%'),
                },
                {
                    field: "itemTotal",
                    sortable: true,
                    header: "lbl_sales_orders_o_info_items_item_total",
                    ...TawreedTableFormatters.decimal("itemTotal", user?.scale, CssUtils.width('20%')),
                },
            ]
            :
            [
                {
                    dataType: 'text',
                    field: "storeItemId",
                    sortable: true,
                    header: "lbl_store_product_item_id",
                    style: CssUtils.width('10%'),
                    headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right'),
                    bodyStyle: { 'unicode-bidi': 'plaintext' }
                },
                {
                    dataType: 'text',
                    field: "productName",
                    sortable: true,
                    header: "lbl_sales_orders_o_info_items_product_name",
                    style: CssUtils.width('20%'),
                    headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right'),
                    bodyStyle: { 'unicode-bidi': 'plaintext' }
                },
                {
                    dataType: 'numeric',
                    field: "quantityOrdered",
                    sortable: true,
                    headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right'),
                    header: "lbl_sales_orders_o_info_items_quantity_ordered",
                    style: CssUtils.width('10%'),
                },
                {
                    dataType: 'numeric',
                    field: "quantity",
                    sortable: true,
                    headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right'),
                    header: "lbl_sales_orders_o_info_items_quantity",
                    body: props.showEdit ? TawreedTableEditor.numeric('int', 'quantityOrdered', 0, !AuthUtils.current().authorized(TawreedActionName.OrdersEditQuantity), "60px") : undefined,
                    style: CssUtils.width('10%'),
                },
                {
                    field: "retailPrice",
                    sortable: true,
                    header: "lbl_sales_cart_store_cart_items_retail_price",
                    headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right'),
                    ...TawreedTableFormatters.decimal("retailPrice", user?.scale),
                    style: CssUtils.width('10%'),
                    className: CssUtils.className.field.amount,
                },
                {
                    field: "discountValue",
                    sortable: true,
                    header: "lbl_base_discount",
                    headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right'),
                    ...TawreedTableFormatters.currencyOrPercentageDepend("discountValue", 'discountType', constants?.info?.currencyCode ?? '', user?.scale!),
                    style: CssUtils.width('10%'),
                    className: CssUtils.className.field.amount,
                },
                {
                    dataType: 'numeric',
                    field: "discountBonus",
                    headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right'),
                    header: "lbl_additional_discount",
                    body: props.showEdit ? TawreedTableEditor.numeric('decimal', undefined, 0, !AuthUtils.current().authorized(TawreedActionName.OrdersEditBonus), "60px", user?.scale) : undefined,
                    style: CssUtils.width('10%'),
                },
                {
                    dataType: 'numeric',
                    field: "bonus",
                    header: "lbl_store_products_bonus",
                    body: props.showEdit ? TawreedTableEditor.numeric('int', undefined, 0, !AuthUtils.current().authorized(TawreedActionName.OrdersEditBonus), "60px", user?.scale) : undefined,
                    style: CssUtils.width('10%'),
                },
                {
                    dataType: 'numeric',
                    sortable: false,
                    filter: false,
                    field: "bonus",
                    header: "lbl_store_products_applied_bonus",
                    style: CssUtils.width('15%'),
                    body: props.showEdit ? (options: any) => (
                        <React.Fragment>
                            {
                                options.appliedBonus && options.appliedBonus.length > 0 &&
                                !props.showEdit &&
                                <div className="bonuses">
                                    {options.appliedBonus.map((e: any) => {
                                        if (e.bonusType === IBonusType.EXTRA_ITEMS)
                                            return <div className="bonuses-item">{e.quantity} + {e.bonus}</div>
                                        else
                                            return <div className="bonuses-item">{e.quantity}{e.bonusOperation === IBonusOperations.GREATER_THAN ? " < " : " * "}  {e.bonus} {getBox(e.discountType!)}</div>
                                    })
                                    }
                                </div>
                            }
                            {
                                options.appliedBonus && options.appliedBonus.length > 0 &&
                                props.showEdit &&
                                <div className="bonuses">
                                    {options.appliedBonus.map((e: any) => {
                                        if (e.bonusType === IBonusType.EXTRA_ITEMS)
                                            return (
                                                <div className="">
                                                    <Button type="button" label={`${e.quantity} + ${e.bonus}`} onClick={() => {
                                                        setSelectedItem(options);
                                                        setUpdateItemDialog(true);
                                                    }
                                                    } className="p-button-rounded p-button-text p-button-danger bonuses-item" />
                                                </div>)
                                        else
                                            return <div className="">
                                                <Button type="button"
                                                    label={`${e.quantity} ${e.bonusOperation === IBonusOperations.GREATER_THAN ? " < " : " * "}  ${e.bonus} ${getBox(e.discountType!)}`}
                                                    onClick={() => {
                                                        setSelectedItem(options);
                                                        setUpdateItemDialog(true);
                                                    }
                                                    } className="p-button-text p-button-danger bonuses-item" />
                                            </div>
                                    })
                                    }
                                </div>
                            }

                            {
                                (!options.appliedBonus || options.appliedBonus.length === 0) &&
                                <Button style={{ minWidth: "80px", justifyContent: 'center' }}  type="button" label={tawreedTranslationService.translate("lbl_add_bonus")} onClick={() => {
                                    setSelectedItem(options);
                                    setUpdateItemDialog(true);
                                }
                                } className="p-button-rounded p-button-text p-button-danger" />
                            }
                        </React.Fragment>
                    ):undefined

                },
                {
                    field: "storeProductTax",
                    sortable: true,
                    header: "lbl_salesordersitem_totaltax",
                    // ...TawreedTableFormatters.percentage("storeProductTax", user?.scale!),
                    style: CssUtils.width('5%'),
                    headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right'),
                    className: CssUtils.className.field.amount,
                },
                {
                    field: "itemTotal",
                    sortable: true,
                    header: "lbl_sales_orders_o_info_items_item_total",
                    ...TawreedTableFormatters.decimal("itemTotal", user?.scale),
                    headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right'),
                    style: CssUtils.width('10%'),
                    className: CssUtils.className.field.amount,
                },
            ];
        // if (props.showEdit){
        //     columns = [...columns, {
        //         field: "delete",
        //         className: CssUtils.className.field.amount,
        //         style: CssUtils.width('2%'),
        //         body: (options: any) => {
        //             return (
        //                 <Button type="button" icon={PrimeIcons.PENCIL} onClick={() => 
        //                     {
        //                         setSelectedItem(options);
        //                         setUpdateItemDialog(true);
        //                     }
        //                 } className="p-button-rounded p-button-text p-button-danger" />
        //             )
        //         }
        //     }];
        // }
        const tawreed: TawreedTableProps = {
            ds: {
                mode: 'lazy',
                onSearch: (q) => service.searchItems(props.orderId!, q),
            },
            pagination: true,
            reload: props.reload,
            name: 'tbl.sales.orders.order.items',
            dataKey: 'orderItemId',
            columns: columns,
            editable: props.showEdit ? { onEditComplete: (newRowData, _field, _value) => props.onSave(newRowData) } : undefined,
            header: {
                left: {
                    search: false,
                    custom: (
                        <React.Fragment>
                            <span>
                                {tawreedTranslationService.translate('lbl_sales_orders_o_info_items')}
                            </span>
                            {props.showNote && <span style={{ fontSize: '13px', fontWeight: 'normal', padding: '4px' }}>
                                {tawreedTranslationService.translate('lbl_placed_note')}
                            </span>}
                        </React.Fragment>

                    )
                },
            }
        };

        return (<div className={props.className}>
            <TawreedTable {...tawreed} />
            <OrderItemChangeBonusDialog
                hide={() => { setUpdateItemDialog(false) }}
                accept={(data) => {
                    var item = selectedItem!;
                    if (data) {
                        item.appliedBonus = [{ ...data, uniqueId: '', bonusId: -1, bonusType: data.bonusType! }];
                    }
                    else {
                        item.appliedBonus = [];
                    }
                    props.onSave(selectedItem!)
                }}
                singlePrice={selectedItem?.sellingPrice}
                visible={updateItemDialog}
                appliedBonus={selectedItem?.appliedBonus && selectedItem?.appliedBonus.length > 0
                    ? selectedItem?.appliedBonus.at(0) :
                    undefined}
            />
        </div>);
    }
