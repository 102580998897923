import {JObject} from "../../../../common/data/models";
import { TransactionChartAllDto } from "../../domain/dtos/dashboard";

export const TransactionValuesChartMapper = {

      
    fromJson(json?: Array<JObject>): TransactionChartAllDto {
        if (!json){
            return {
                label: [],
                values: []
            }
        }
        var finalResult: TransactionChartAllDto = {
            label: [],
            values: []
        };
        finalResult.label = json.map(e => {
            return e.label
        });
        finalResult.label =  finalResult.label.filter((item, index) => finalResult.label.indexOf(item) === index);
        const result = json.reduce((acc, item) => {
            const graphName = item.graphName;
            if (!acc[graphName]) {
              acc[graphName] = [];
            }
            acc[graphName].push(item);
            return acc;
          }, {});

        for (const [objectKey, objectValue] of Object.entries(result)) {
            var newObjectValue = [];
            for(var i =0; i<finalResult.label.length; i++){
                var checkExits = objectValue.filter((x:JObject)=>x.label === finalResult.label.at(i));
                if(checkExits.length>0){
                    newObjectValue.push({
                        value: checkExits.at(0).value,
                        value2: checkExits.at(0).value2
                    })
                }
                else{
                    newObjectValue.push({
                        value: 0,
                        value2: 0
                    })
                }
            }
            finalResult.values.push(
                {
                    count: {
                        name: objectKey,
                        color: '#FFA726',
                        value: newObjectValue.map((e:JObject) => {
                            return {
                                // name: e.label,
                                // label: e.label,
                                value: e.value,
                            }
                        }),
                    },
                    amount: {
                        name: objectKey,
                        color: '#42A5F5',
                        value: newObjectValue.map((e:JObject) => {
                            return {
                                // name: e.label,
                                // label: e.label,
                                value: e.value2,
                            }
                        }),
                    },
                }
            );
    };
    console.log("finalResult",finalResult);
    return finalResult;
}
}
