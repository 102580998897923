import React from "react";
import {tawreedTranslationService} from "../../../../common/translations";
import {Chart} from "primereact/chart";
import {Skeleton} from "primereact/skeleton";
import {Card} from "primereact/card";
import {TawreedCalendar} from "../../../../common/components/form";
import {SelectButton} from "primereact/selectbutton";
import {PrimeIcons} from "primereact/api";
import {DateUtils} from "../../../../helpers/date";
import { AuthUtils } from "../../../auth/domain";
import { DashboardItemValue, TransactionChartAllDto } from "../../domain/dtos/dashboard";
import { Button } from "primereact/button";


declare type DashboardFinanceTransactionChartProps = {

    className: string;

    /**
     *
     */
    loading: boolean;

    /**
     * dto
     */
    dto: TransactionChartAllDto | undefined;

    /**
     *
     * @param startDate
     * @param endDate
     */
    onSearch(startDate: Date, endDate: Date): void;

    onDetails(startDate: Date, endDate: Date, name: string): void;
    /**
     *
     * @param value
     */
    calculator: (value: DashboardItemValue) => string;
    name: string;
}

declare type DashboardFinanceTransactionChartState = {

    selected: 'amount' | 'count',
    data: {
        /**
         *
         */
        labels: Array<string>;

        /**
         *
         */
        datasets: Array<{ label: string; backgroundColor: string; data: Array<any>; barThickness?: number, maxBarThickness?: number }>;
    };
    //
    available: Array<{ label: string; value: 'amount' | 'count' }>;
    //
    startDate: Date;
    endDate: Date;
    paymentModeIds?: number[]
}

export const DashboardFinanceTransactionChart: React.FC<DashboardFinanceTransactionChartProps> = (props) => {

    const [state, setState] = React.useState<DashboardFinanceTransactionChartState>({
        selected: 'count',
        data: {
            labels: [],
            datasets: [
                {
                    label: tawreedTranslationService.translate('lbl_orderdashboard_orders_by_payment_mode_chart_title'),
                    backgroundColor: '#FFA726',
                    data: []
                }
            ]
        },
        //
        available: [
            {
                label: tawreedTranslationService.translate('lbl_dashboard_orders_totals_chart_total_count'),
                value: 'count',
            },
            {
                label: tawreedTranslationService.translate('lbl_dashboard_orders_totals_chart_total_amount'),
                value: 'amount',
            },
        ],
        //
        startDate: DateUtils.now(-7),
        endDate: DateUtils.now(),
    });

    React.useEffect(() => {

        let mounted = true;

        if (mounted && !props.loading) {
            props.onSearch(state.startDate, state.endDate);
        }

        return () => {
            mounted = false;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.startDate, state.endDate]);

    React.useEffect(
        () => {
             setState(previous => {
                            return {
                                ...previous,
                                data: {
                                    labels: props.dto ? props.dto.label : [],
                                    datasets: props.dto ? props.dto.values.map((x)=> {return {
                                            label: x.amount.name || tawreedTranslationService.translate(`lbl_finance_dashboard_${props.name}_chart_title`),
                                            backgroundColor: x ? x[previous.selected].color : '#FFA726',
                                            data: x ? x[previous.selected].value.map(e => props.calculator(e.value)) : [],
                                            barThickness: 30,
                                            maxBarThickness: 32
                                        }}) : []
                                },
                            };
                        });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.dto, props.calculator, state.selected]);

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                align: 'end',
                labels: {
                    font: {
                        family: AuthUtils.current().language === 'ar' ? '--arabic-font-family' : 'var(--font-family)'
                    },
                    color: '#495057'
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    const onChange = (sender: 'startDate' | 'endDate', value: Date) => {
        setState({...state, [sender]: value});
    }

    function onSelectedDataSetChange(e: 'amount' | 'count') {
        setState({...state, selected: e});
    }

    const header = () => {
        return (
            <div className="grid">
                <div className="col-9">
                    <span>
                        {tawreedTranslationService.translate(`lbl_finance_dashboard_${props.name}_chart_title`)}
                    </span>
                </div>
                <div className="col-3">
                                        <div className="flex justify-content-start sm:justify-content-end">
                                            <Button tooltip={tawreedTranslationService.translate("lbl_finance_header")} icon={PrimeIcons.EYE}
                                                onClick={() => props.onDetails(state.startDate, state.endDate,
                                                    props.name)} />
                                        </div>
                                    </div>
                <div className="col-12">
                    <div className="grid">
                        <div className="col-12 sm:col-6">
                            <SelectButton className="p-button-sm" optionLabel="label" optionValue="value" value={state.selected} options={state.available} onChange={(e) => onSelectedDataSetChange(e.value)}/>
                        </div>
                        <div className="col-12 sm:col-6">
                            <div className="flex justify-content-start sm:justify-content-end">
                                <i className={"my-auto mr-2 " + PrimeIcons.CALENDAR} style={{fontSize: '1.5rem'}}/>
                                <div style={{width: '10rem'}}>
                                    <TawreedCalendar render="standalone" disabled={props.loading} value={state.startDate} onChange={e => onChange('startDate', e.value as Date)}/>
                                </div>
                                <div style={{width: '1rem', height: '1rem'}}/>
                                <div style={{width: '10rem'}}>
                                    <TawreedCalendar render="standalone" disabled={props.loading} value={state.endDate} onChange={e => onChange('endDate', e.value as Date)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <Card className={"card " + props.className} header={header()}>
            {
                (props.loading) ? <Skeleton/> : <Chart key="tawreed_dashboard_orders_by_payment_mode_chart" id="tawreed_dashboard_orders_totals_chart" type="line" height="16rem" data={state.data} options={options} style={{width: '100%'}}/>
            }
        </Card>
    )
}
