import { tawreedHttpService } from "../../../../http";
import { endpoints } from "../../../../constants/endpoints";
import { JObject } from "../../../../common/data/models";
import { OrdersTotalsChartMapper } from "../mappers";
import { OrdersPerPaymentModeChartAllDto, OrdersTotalsChartDto } from "../../domain";
import { DateUtils } from "../../../../helpers/date";
import { OrdersPerPaymentModeChartMapper } from "../mappers/orders-per-payment-mode-chart";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../common/pagination";
import { OrderDto } from "../../../sales/orders/domain";
import { OrderMapper } from "../../../sales/orders/data/mappers";
import { PartnerMapper, PartnerType } from "../../../partners/data";

export class OrderDashboardRepository {

    public getOrdersChartsTotals(startDate: Date, endDate: Date, customerIds: number[] | undefined, storeIds: number[] | undefined,
        paymentModeIds: number[] | undefined, priceListIds: number[] | undefined, statusIds: number[] | undefined): Promise<OrdersTotalsChartDto> {
        return tawreedHttpService
            .post<JObject, Array<JObject>>(endpoints.ORDER_DASHBOARD_ORDERS_TOTALS_CHART, {
                startDate: DateUtils.toISO(startDate, 'datetime', 'start'),
                endDate: DateUtils.toISO(endDate, 'datetime', 'end'),
                storeIds: storeIds,
                customerIds: customerIds,
                priceListIds: priceListIds,
                paymentModeIds: paymentModeIds,
                statusIds: statusIds
            }, undefined, undefined, 'error')
            .then(res => OrdersTotalsChartMapper.fromJson(res.data));
    }

    public getPaymentModeOrdersChartsTotals(startDate: Date, endDate: Date,
        paymentModeIds: number[] | undefined): Promise<OrdersPerPaymentModeChartAllDto> {
        return tawreedHttpService
            .post<JObject, Array<JObject>>(endpoints.ORDER_DASHBOARD_PAYMENT_MODE_ORDERS_CHART, {
                startDate: DateUtils.toISO(startDate, 'datetime', 'start'),
                endDate: DateUtils.toISO(endDate, 'datetime', 'end'),
                paymentModeIds: paymentModeIds,
            }, undefined, undefined, 'error')
            .then(res => {
                return OrdersPerPaymentModeChartMapper.fromJson(res.data!);
            });
    }

    public search(arg: PaginatedRequestParams, returned?: boolean): Promise<PaginatedResponse<OrderDto>> {
        const endpoint: string = returned ? endpoints.RETURNED_ORDERS_SEARCH : endpoints.ORDERS_SEARCH;
        const params = {
            sort: arg.sort ? buildSortParamsToString(arg.sort) : undefined,
            page: arg.page,
            size: arg.size
        };
        const data = { ...buildFilterMetaToObject(arg.keyword, arg.filters) };

        return tawreedHttpService
            .post<JObject, Result<JObject>>(endpoint, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res,
                    data: {
                        ...res.data!,
                        content: res.data!.content.map(e => this.fromJsonToOrderDto2(e)) ?? [],
                    }
                }
            });
    }
    private fromJsonToOrderDto2(json: JObject | undefined): OrderDto {

        const customer = PartnerMapper.toDto(PartnerMapper.fromJson({
            partnerId: json?.customerId,
            partnerName: json?.customer,
            partnerType: PartnerType.Customer,
            state: json?.customerStateName,
            contact: {
                contactId: json?.contact?.contactId,
                contactName: json?.contact?.contactName,
                email: json?.contact?.email,
                mobileNo: json?.contact?.mobileNo,
            },
            address: {
                addressId: json?.address?.addressId,
                state: json?.address?.state,
                stateId: json?.address?.stateId,
                zoneId: json?.address?.zoneId,
                latitude: json?.address?.latitude,
                longitude: json?.address?.longitude,
                street: json?.address?.street,
                fullAddress: json?.address?.fullAddress,
            },
        }));

        const store = PartnerMapper.toDto(PartnerMapper.fromJson({
            partnerId: json?.storeId,
            partnerName: json?.store,
            partnerType: PartnerType.Store,
            contact: {
                contactId: json?.storeContact?.contactId,
                contactName: json?.storeContact?.contactName,
                email: json?.storeContact?.email,
                mobileNo: json?.storeContact?.mobileNo,
            },
            address: {
                addressId: json?.storeAddress?.addressId,
                state: json?.storeAddress?.state,
                stateId: json?.storeAddress?.stateId,
                zoneId: json?.storeAddress?.zoneId,
                latitude: json?.storeAddress?.latitude,
                longitude: json?.storeAddress?.longitude,
                street: json?.storeAddress?.street,
                fullAddress: json?.storeAddress?.fullAddress,
            },
        }));
        return OrderMapper.toDto(OrderMapper.fromJson(json!, customer, store));
    }


}
