import React, { useEffect, useMemo, useState } from "react";
import { DashboardItemValue, pharmaciesPerGovernateChartDto } from "../../domain";
import { tawreedTranslationService } from "../../../../common/translations";
import { Chart } from "primereact/chart";
import { Skeleton } from "primereact/skeleton";
import { Card } from "primereact/card";
import { AuthUtils } from "../../../auth/domain";
import { GlobalContext } from "../../../../context";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";


declare type DashboardPharmaciesPerGovernateChartProps = {

    /**
        *
        */
    className: string;

    /**
     *
     */
    loading: boolean;

    /**
     * counts
     */
    counts: pharmaciesPerGovernateChartDto | undefined;

    onDeatils: () =>void;

    calculator: (value: DashboardItemValue) => string;
}


export const DashboardPharmaciesPerGovernateChart: React.FC<DashboardPharmaciesPerGovernateChartProps> = (props) => {

    const { constants: { constants } } = React.useContext(GlobalContext);


    const chartData = {
        labels: props.counts?.data.map(e => tawreedTranslationService.translate(e.label)),
        datasets: [
            {
                data: props.counts?.data.map(e => props.calculator(e.value)),
                backgroundColor: props.counts?.color,
                hoverBackgroundColor: props.counts?.color,
                weight: .5,
                label: '',
            },
        ]
    };

    const lightOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            title: {
                display: true,
                //text: props.counts?.value ? tawreedTranslationService.translate(props.counts?.total.label, props.calculator(props.counts?.total.value)) : '',
            },
            legend: {
                position: 'left',
                align: 'center',
                labels: {
                    font: {
                        family: AuthUtils.current().language === 'ar' ? '--arabic-font-family' : 'var(--font-family)'
                    },
                    color: '#495057'
                }
            },
        }
    };


    const header = () => {
        return (
            <div className="grid">
                <div className="col-9">
                    <span>
                        {tawreedTranslationService.translate('lbl_partnerdashboard_pharmacies_per_governate_chart_title')}
                    </span>
                </div>
                <div className="col-3 sm:col-3">
                    <div className="flex justify-content-start sm:justify-content-end">
                        <Button tooltip={tawreedTranslationService.translate("lbl_finance_header")} icon={PrimeIcons.EYE}
                            onClick={() => props.onDeatils()} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Card className={"card " + props.className} header={header()}>
            {
                (props.loading) ? <Skeleton /> : <Chart key="tawreed_dashboard_pharmacies_per_governate_chart" id="tawreed_dashboard_pharmacies_per_governate_chart" type="bar" height="16rem" data={chartData} options={lightOptions} style={{ width: '100%' }} />
            }
        </Card>
    )
}
