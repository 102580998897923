import { JObject } from "../../../../common/data/models";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../common/pagination";
import { endpoints } from "../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../http";
import { FileService } from "../../../upload";
import { PaymentPartner } from "../../domain";
import { PaymentPartnerMapper } from "../mappers";

export class PaymentPartnerRepository {

    private filesService = new FileService();

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<PaymentPartner>> {

        const params = {
            sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
            page: request.page,
            size: request.size,
        };
        const data = { ...buildFilterMetaToObject(request.keyword, { ...request.filters }) };

        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.PAYMENT_PARTNERS_SEARCH, data, params, undefined, 'error')
            .then(res => {
                return {
                    ...res!,
                    data: { ...res!.data!, content: res!.data!.content.map(e => PaymentPartnerMapper.fromJson(e)) ?? [] },
                };
            });
    }

    /**
     * Create or update partner instance
     * @param type
     * @param form
     */
    public async createOrUpdate(form: PaymentPartner): Promise<PaymentPartner> {
        await this.createOrUpdateLogo(form);
        const data = PaymentPartnerMapper.toJson(form);
        return tawreedHttpService.post<JObject, JObject>(endpoints.PAYMENT_PARTNERS_CREATE_OR_SAVE, data)
            .then(res => {
                return PaymentPartnerMapper.fromJson(res.data!);
            });
    }

    private async createOrUpdateLogo(form: PaymentPartner): Promise<void> {
        if (form && form.logoContent && form.logoContent.value instanceof File) {
            form.logoContent.value = await this.filesService.upload(form.logoContent);
        }
    }

    /**
     * Delete partner by id
     * @param id
     */
    public async delete(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, number>(endpoints.PAYMENT_PARTNERS_DELETE, id).then(res => {
            return res.status === 200
        });
    }

    /**
     * Delete all partners by ids
     * @param ids
     */
    public async deleteAll(ids: number[]): Promise<boolean> {
        return tawreedHttpService.post<number[], number>(endpoints.PAYMENT_PARTNERS_DELETE_ALL, ids).then(res => res.status === 200);
    }

    /**
     * Get partner details by id
     * @param id
     */
    public async getDetails(id: number): Promise<PaymentPartner> {
        return tawreedHttpService.post<number, JObject>(endpoints.PAYMENT_PARTNERS_GET_DETAILS, id, undefined, undefined, 'error')
        .then(res => PaymentPartnerMapper.fromJson(res.data!));
    }

    public activateAll(ids: number[]): Promise<boolean> {
        return tawreedHttpService.post<number[], boolean>(endpoints.PAYMENT_PARTNERS_ACTIVATE_ALL, ids,undefined,undefined,'error').then(res => {
            return res.status === 200
        });
    }

    public deactivateAll(ids: number[]): Promise<boolean> {
        return tawreedHttpService.post<number[], JObject>(endpoints.PAYMENT_PARTNERS_DEACTIVATE_ALL, ids).then(res => {
            return res.status === 200
        });
    }
}