import React from "react";
import { AutoCompleteChangeParams, AutoCompleteSelectParams } from "primereact/autocomplete";
import { TawreedAutoComplete, TawreedControlRenderModeWithoutNested, TawreedControlRules } from "../../../../common";
import { GlobalContext } from "../../../../context";
import { AccountDto } from "../../domain";
import { Tag } from "primereact/tag";
import { ObjectUtils } from "../../../../helpers/object";
import { AccountsService } from "../../domain/services/accounts.service";

export type AccountAutoCompleteComponentProps = {
    //
    accountType?: number;

    // autocomplete
    autoCompleteValue?: AccountDto;
    autoCompleteName: string;
    autoCompleteRender: TawreedControlRenderModeWithoutNested;
    autoCompleteDisabled?: boolean;
    autoCompleteRules?: TawreedControlRules;
    autoCompleteField: string;
    autoCompleteOnSelect?(e: AutoCompleteSelectParams): void;
    autoCompleteOnChange?(e: AutoCompleteChangeParams): void;
};

export const AccountAutoCompleteComponent: React.FC<AccountAutoCompleteComponentProps> = (props) => {

    const { constants: { constants } } = React.useContext(GlobalContext);
    const service: AccountsService = React.useMemo(() => new AccountsService(), []);
    const search = (q: string) => {
        return service
            .search(props.accountType, q)
            .then(res => {
                const result = [];
                if (res && res.content) {
                    result.push(...res.content);
                }
                return result;
            });
    };


    const itemTemplate = (rowData: AccountDto) => {

        let accountType;
        if (constants && constants.financeAccountsTypes && constants.financeAccountsTypes.length) {
            accountType = ObjectUtils.fromMenu(rowData.accountType, constants.financeAccountsTypes);
        } else {
            accountType = rowData.accountType;
        }

        return (
            <div className="flex">
                <span className="mr-auto">{rowData.accountTitle}</span>
                {/* <Tag rounded severity='success' style={{ width: '5rem' }} value={accountType} /> */}
            </div>
        );
    }

    return (
        <TawreedAutoComplete name={props.autoCompleteName}
            render={props.autoCompleteRender}
            disabled={props.autoCompleteDisabled}
            rules={props.autoCompleteRules}
            field={props.autoCompleteField}
            value={props.autoCompleteValue}
            scrollHeight={'25em'}
            onSelect={props.autoCompleteOnSelect}
            onChange={props.autoCompleteOnChange}
            onSearch={search}
            itemTemplate={itemTemplate} />
    );
}
